import {
  type UseQueryOptions,
  queryOptions,
  useQuery,
} from "@tanstack/react-query";
import { HTTPError } from "ky";
import { useMemo } from "react";
import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { useUserAgent } from "~features/user-agent";
import { useAccessTokenStore } from "./use-access-token";
import { EMPTY_USER, type User, parseUserResponse } from "./user.schemas";

export const useUser = () => {
  const queryResult = useQueryUser();

  const isLoggedIn = useMemo(
    () => queryResult.isSuccess && (queryResult.data?.id ?? "").length > 0,
    [queryResult.isSuccess, queryResult.data?.id],
  );

  const user = useMemo(
    () =>
      queryResult.isSuccess && queryResult.data ? queryResult.data : EMPTY_USER,
    [queryResult.isSuccess, queryResult.data],
  );

  return {
    user,
    isLoggedIn,
    queryResult,
  };
};

const useQueryUser = () => {
  const { isWeb } = useUserAgent();
  const { accessToken } = useAccessTokenStore();

  const queryOption = useMemo(
    () =>
      Object.assign({}, userQueryOptions, {
        enabled: isWeb || accessToken.length > 0,
        retry(failureCount, error: HTTPError) {
          if (!(error instanceof HTTPError)) return false;
          return error.response.status >= 500 && failureCount < 3;
        },
      } as UseQueryOptions<User>),
    [accessToken, isWeb],
  );

  return useQuery(queryOption);
};

const fetchUser = async () => KyClient.get("users/me").json();

export const userQueryOptions = queryOptions({
  queryKey: QUERY_KEYS.AUTH.USER,
  queryFn: () => fetchUser().then(parseUserResponse),
  staleTime: TIMES.MIN * 5,
  refetchInterval: TIMES.MIN * 5,
  refetchOnWindowFocus: true,
  retry: false,
});
