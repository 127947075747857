import { create } from "zustand";
import type { SearchResult } from "../schemas";

type SearchInAnswerItem = Partial<
  Omit<SearchResult.Item, "sources" | "videos" | "images" | "questions">
>;

type SearchInAnswerStates = {
  items: Map<string, SearchInAnswerItem>;
  updateItem: (channelId: string, item: SearchInAnswerItem) => void;
  deleteItem: (channelId: string) => void;
};

export const useSearchInAnswer = create<SearchInAnswerStates>((set) => ({
  items: new Map<string, SearchInAnswerItem>(),

  updateItem: (channelId, item) =>
    set((state) => {
      const newItems = new Map(state.items);
      const prevItem = newItems.get(channelId) ?? { toolSteps: [] };
      const newItem = Object.assign({}, prevItem, item, {
        toolSteps:
          (prevItem.toolSteps?.length ?? 0) > (item.toolSteps?.length ?? 0)
            ? prevItem.toolSteps
            : (item.toolSteps ?? []),
      } as SearchInAnswerItem);
      newItems.set(channelId, newItem);

      return {
        items: newItems,
      };
    }),

  deleteItem: (channelId) =>
    set((state) => {
      const nextItems = new Map(state.items);
      nextItems.delete(channelId);

      return {
        items: nextItems,
      };
    }),
}));
