import type { PropsWithChildren } from "react";

import { cn } from "~utils/class-names";
import styles from "./toc-list.module.scss";

type TocListProps = PropsWithChildren<{
  isSimplified?: boolean;
}>;

const TocList = ({ isSimplified, children }: TocListProps) => {
  return (
    <div
      className={cn(styles.toc_list, {
        [styles.simple]: isSimplified,
      })}
    >
      {children}
    </div>
  );
};

export default TocList;
