import { useUser } from "~features/auth";
import { useMutationSendPromptWithNewChannel } from "~features/prompt";
import {
  useCreateChannelAndNavigate,
  useRequestValidation,
  validateRequestWithNewChannel,
} from "~features/prompt/mutations";
import { Carousel } from "~features/ui/carousel";
import { createEventHandler } from "~utils/create-event-handler";
import type { SearchResult } from "../schemas";

type SearchResultVideosProps = SearchResult.VideoSources & {
  title?: string;
};

export function SearchResultVideos({
  title = "동영상",
  total,
  items,
}: SearchResultVideosProps) {
  const { user } = useUser();
  const { matchValidationResult } = useRequestValidation();
  const { createChannelAndNavigate } = useCreateChannelAndNavigate();
  const { mutate } = useMutationSendPromptWithNewChannel(user);

  const onClickSummaryButton = (videoId: string) =>
    createEventHandler({
      handler: () => {
        const youtubeUrl = `https://www.youtube.com/watch?v=${videoId}`;

        const validationResult = validateRequestWithNewChannel({
          user,
          prompt: youtubeUrl,
        });

        return matchValidationResult(validationResult, {
          withValid: () => {
            createChannelAndNavigate({ isForYoutubeSummary: true }).then(
              ({ channelId, personaId }) => {
                mutate({
                  prompt: youtubeUrl,
                  channelId,
                  personaId,
                  isForYoutubeSummary: true,
                });
              },
            );
          },
        });
      },
    });

  return (
    <Carousel.List title={title} total={total}>
      {items.map((video) => (
        <Carousel.Card
          key={`result-video-${video.id}-${video.videoId}`}
          cardType="video"
          cardId={video.id}
          cardThumbnailSrc={getYoutubeThumbnailSrc({
            videoId: video.videoId,
            thumbnailSrc: video.thumbnailSrc,
          })}
          cardTitle={video.title}
          cardSource={video.source}
          cardUpdatedAt={video.updatedAt}
          cardUrl={video.url}
          onClickButton={onClickSummaryButton(video.videoId ?? "")}
        />
      ))}
    </Carousel.List>
  );
}

const getYoutubeThumbnailSrc = ({
  videoId,
  thumbnailSrc,
}: {
  thumbnailSrc?: string;
  videoId?: string;
}) => {
  if (thumbnailSrc) {
    return thumbnailSrc;
  }

  if (!videoId) return "";

  /** @see {@link https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api} */
  return `https://img.youtube.com/vi/${videoId}/sddefault.jpg`;
};
