import { IconVariants } from "./enums";
import { SearchIcon } from "./search-icon";
import { UrlIcon } from "./url-icon";
import { YoutubeIcon } from "./youtube-icon";

type PromptIconProps = {
  variants: IconVariants;
};

export function PromptIcon({ variants }: PromptIconProps) {
  if (variants === IconVariants.Search) return <SearchIcon />;
  if (variants === IconVariants.Youtube) return <YoutubeIcon />;
  if (variants === IconVariants.Url) return <UrlIcon />;
  return null;
}
