export type { InferOutput } from "valibot";

import {
  any,
  array,
  boolean,
  custom,
  enum_,
  fallback,
  literal,
  minLength,
  nonEmpty,
  nullable,
  number,
  object,
  optional,
  parse,
  pipe,
  safeParse,
  string,
  transform,
  trim,
  union,
  unknown,
  url,
  null as vNull,
} from "valibot";

export const v = {
  string,
  number,
  boolean,
  object,
  array,
  enum: enum_,
  custom,
  url,

  any,
  unknown,
  literal,
  union,
  optional,
  nullable,
  null: vNull,
  fallback,

  minLength,
  nonEmpty,

  transform,
  pipe,
  parse,
  safeParse,
  trim,
} as const;
