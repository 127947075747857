import { createContext, useContext } from "react";
import type { SearchResult } from "~features/search/schemas";

type WebSourcesContextStates = {
  /** @description SourceId => Source */
  webSources: Map<string, SearchResult.WebSource>;
};

export const WebSourcesContext = createContext<WebSourcesContextStates | null>(
  null,
);

export const useWebSourcesContext = () => {
  const context = useContext(WebSourcesContext);
  if (!context) {
    throw new Error(
      "useWebSourcesContext must be used within a WebSourcesProvider",
    );
  }

  return context;
};
