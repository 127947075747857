import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import type { SearchResult } from "~features/search/schemas";
import { Status } from "~features/util-types/status";
import { cloneObject } from "~utils/clone-object";
import { sendMessage } from "./send-message";

type Args = {
  userId: string;
  channelId: string;
  personaId: string;
};

export const useMutationSendPromptInChannel = ({
  channelId,
  userId,
  personaId,
}: Args) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (prompt: string) =>
      sendMessage({
        message: prompt,
        userId,
        channelId,
        personaId,
      }),

    onMutate: async (prompt) => {
      const queryKey =
        QUERY_KEYS.CHANNELS.getChannelMessagesQueryKey(channelId);

      await queryClient.cancelQueries({ queryKey });

      queryClient.setQueryData<SearchResult.Page>(queryKey, (prevMessages) => {
        const newMessages = cloneObject(
          prevMessages ?? {
            items: [],
            total: 0,
          },
        );

        if (newMessages.items.slice(-1)[0]) {
          newMessages.items.slice(-1)[0].questions = undefined;
        }

        const newMessage: SearchResult.Item = createNewMessage(prompt);
        newMessages.items.push(newMessage);
        newMessages.total += 1;

        return newMessages;
      });

      return {
        queryKey,
      };
    },

    onSettled: (_response, _error, _prompt, context) => {
      if (!context) return;
      queryClient.refetchQueries({ queryKey: context.queryKey });
    },
  });
};

const INIT_MESSAGE: Omit<SearchResult.Item, "title"> = {
  id: "",
  messageId: { user: "", assistant: "" },
  requestId: "",
  status: Status.INIT,
  toolSteps: [
    {
      order: 0,
      speak: "질문의 의도를 이해하고 있어요.",
      status: Status.LOADING,
    },
  ],
  content: "",
};

const createNewMessage = (prompt: string): SearchResult.Item =>
  Object.assign({}, INIT_MESSAGE, { title: prompt });
