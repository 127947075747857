import { useCallback, useMemo } from "react";
import { create } from "zustand";
import type { Persona } from "./personas.schemas";
import { useQueryPersonas } from "./use-query-personas";

type PersonaStates = {
  persona: Persona;
};

type PersonaActions = {
  setPersona: (persona: Persona) => void;
};

const INIT_STATES: PersonaStates = {
  persona: {
    id: "",
    type: "",
    theme: "",
    name: "",
  },
};

const usePersonaStore = create<PersonaStates & PersonaActions>((set) => ({
  ...INIT_STATES,
  setPersona: (persona) => set({ persona }),
}));

/** @todo query 로직 분리? */
export const usePersonas = () => {
  const { data: personas = [], isSuccess, isPending } = useQueryPersonas();
  const getPersonaById = useCallback(
    (personaId: string) => personas.find(({ id }) => id === personaId),
    [personas],
  );
  const { persona, setPersona } = usePersonaStore();

  const memoizedPersonas = useMemo(() => {
    const searchPersona = personas.find(({ theme }) => theme === "realtime");
    const searchPersonaId = searchPersona?.id ?? "";

    const youtubePersona = personas.find(({ theme }) => theme === "youtube");
    const youtubePersonaId = youtubePersona?.id ?? "";

    return {
      searchPersona,
      searchPersonaId,
      youtubePersona,
      youtubePersonaId,
    };
  }, [personas]);

  return {
    personas,
    getPersonaById,
    isSuccess,
    isPending,
    persona,
    setPersona,
    ...memoizedPersonas,
    isYoutubePersona: persona.id === memoizedPersonas.youtubePersonaId,
  };
};
