import { type InferOutput, v } from "~libs/valibot";
import { logger } from "~utils/logger";

export type SearchRecommendation = InferOutput<typeof SearchRecommendation>;

export const INIT_SEARCH_RECOMMENDATIONS: SearchRecommendation = {
  keywords: [],
};

/**
 * @example
 * ```json
 * {
    "_id": "6736ccca43ac24382e628f06",
    "date": "2024-11-15T04:23:00.004000",
    "keywords": [
        "밴드 미국 600만 MAU 돌파",
        "한국 ICT 백서 발간",
        "AI 보안관제 시스템",
        "디지털 포렌식랩 구축",
        "북한군 러시아 파병",
        "장보고-III 배치II 잠수함",
        "트럼프 당선 후폭풍",
        "윤대통령 지지율 20% 회복",
        "미국 한국 도청",
        "주일 미군사령부 도쿄 이전"
    ]
  }
 * ```
 */
const SearchRecommendation$Api = v.object({
  // _id: v.string(),
  date: v.string(),
  keywords: v.array(v.string()),
});

const SearchRecommendation = v.pipe(
  SearchRecommendation$Api,
  v.transform((data) => ({
    keywords: data.keywords,
  })),
);

export const transformResponse = (data: unknown) => {
  const parseResult = v.safeParse(SearchRecommendation, data);
  if (parseResult.success) {
    return parseResult.output;
  }

  logger.error({
    scope: "SearchRecommendation.transformResponse",
    data: parseResult.issues,
  });
  return INIT_SEARCH_RECOMMENDATIONS;
};
