import { type PropsWithChildren, useEffect, useState } from "react";
import { MarkdownRenderer } from "~features/markdown-renderer";
import { useMutationCancelMessage } from "~features/messages/hooks";
import { useMessageIntersectionObserver } from "~features/messages/intersection";
import { StreamingContext } from "~features/messages/streaming";
import { MessageTitle } from "~features/messages/ui";
import { StopButton } from "~features/ui/answer/answer-stop-button";
import { Status } from "~features/util-types/status";
import { cn } from "~utils/class-names";
import type { SearchResult } from "../schemas";
import { ResultInnerBox } from "./inner-box";
import { ResultSection } from "./section";
import { ResultSkeleton } from "./skeleton";
import { SearchResultToolSteps } from "./tool-steps";
import { useSocketSearchStreaming } from "./use-socket-search-streaming";

import sharedStyles from "./result.module.scss";
import styles from "./search-streaming.module.scss";

type SearchStreamingProps = {
  channelId: string;
  initSearch: SearchResult.Item;
};

/** @todo 공용 상수 */
const TOOL_STEPS_INIT: SearchResult.ToolSteps[] = [
  {
    order: 0,
    speak: "질문의 의도를 이해하고 있어요.",
    status: Status.LOADING,
  },
];

export function SearchStreaming({
  channelId,
  initSearch,
}: SearchStreamingProps) {
  const { divRef } = useMessageIntersectionObserver(
    `message-streaming-${channelId}`,
  );

  const [status, setStatus] = useState(initSearch.status);
  const [content, setContent] = useState(initSearch.content ?? "");
  const [toolSteps, setToolSteps] = useState<SearchResult.ToolSteps[]>(
    initSearch.toolSteps ?? TOOL_STEPS_INIT,
  );

  useEffect(() => {
    divRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }, []);

  const cancelMutation = useMutationCancelMessage();
  const onClickStopButton = () => {
    cancelMutation.mutate(initSearch.id);
  };

  return (
    <>
      <StreamingContext.Provider
        value={{ setStatus, content, setContent, toolSteps, setToolSteps }}
      >
        <WithSocket channelId={channelId}>
          <ResultSection ref={divRef} className={styles.section}>
            <ResultInnerBox>
              <MessageTitle>{initSearch.title}</MessageTitle>
            </ResultInnerBox>

            <ResultInnerBox>
              <SearchResultToolSteps
                title="답변 과정"
                toolSteps={toolSteps}
                searchStatus={Status.LOADING}
              />
            </ResultInnerBox>

            {status === Status.INIT ? (
              <ResultInnerBox className="flex flex-col gap-2">
                <ResultSkeleton />
              </ResultInnerBox>
            ) : (
              <ResultInnerBox
                className={cn(sharedStyles.markdown_wrap, styles.caret)}
              >
                <MarkdownRenderer contents={content} />
              </ResultInnerBox>
            )}
          </ResultSection>
        </WithSocket>
      </StreamingContext.Provider>

      <StopButton
        onClick={onClickStopButton}
        className="right-1/2 bottom-[72px] z-20 absolute w-[122px] text-nowrap translate-x-1/2 shrink-0"
        isEnabled={initSearch.id.length > 0}
      />
    </>
  );
}

type WithSocketProps = PropsWithChildren<{
  channelId: string;
}>;

function WithSocket({ channelId, children }: WithSocketProps) {
  useSocketSearchStreaming(channelId);

  return children;
}
