import { createContext, useContext } from "react";

type FirstPromptContextStates = {
  firstPrompt: string;
};

export const FirstPromptContext = createContext<FirstPromptContextStates>({
  firstPrompt: "",
});

export const useFirstPromptContext = () => {
  const context = useContext(FirstPromptContext);
  if (!context) {
    throw new Error(
      "useFirstPromptContext must be used within a FirstPromptProvider",
    );
  }

  return context;
};
