import { useCallback } from "react";
import { useUser } from "~features/auth";
import {
  useCreateChannelAndNavigate,
  useMutationSendPromptWithNewChannel,
  useRequestValidation,
  validateRequestWithNewChannel,
} from "~features/prompt/mutations";
import { categorizePrompt } from "~features/prompt/utils";
import { useSendSearchTitle } from "~features/search";
import { useSidebar } from "~features/ui/side-bar";

/** @todo 공통로직 분리 */
export const useSendSuggestion = () => {
  const { user } = useUser();
  const { matchValidationResult } = useRequestValidation();
  const { createChannelAndNavigate } = useCreateChannelAndNavigate();
  const { mutate } = useMutationSendPromptWithNewChannel(user);
  const { requestNativeNotificationPermission } = useSendSearchTitle();

  const { setIsOpen } = useSidebar();

  const sendSuggestion = useCallback(
    async (suggestion: string) => {
      const categorized = categorizePrompt(suggestion);
      const validationResult = validateRequestWithNewChannel({
        prompt: suggestion,
        user,
      });

      return matchValidationResult(validationResult, {
        withValid: () => {
          createChannelAndNavigate({
            isForYoutubeSummary: categorized.hasYoutubeUrl,
          }).then(({ channelId, personaId, isForYoutubeSummary }) => {
            setIsOpen(false);

            mutate({
              prompt: suggestion,
              channelId,
              personaId,
              isForYoutubeSummary,
            });

            requestNativeNotificationPermission();
          });
        },

        createOAuthRedirectUrl: () =>
          encodeURI(window.location.origin.concat(`/search?q=${suggestion}`)),
      });
    },
    [
      user,
      matchValidationResult,
      createChannelAndNavigate,
      mutate,
      requestNativeNotificationPermission,
      setIsOpen,
    ],
  );

  return {
    sendSuggestion,
  };
};
