import { ToastContainerTop, ToastContainerBottom } from "./containers";

export function ToastsCoreLayout() {
  return (
    <>
      <ToastContainerTop />
      <ToastContainerBottom />
    </>
  );
}
