import { useCallback } from "react";
import { match } from "ts-pattern";
import { useNativeBridge } from "~clients/native-bridge";
import { ERROR_MESSAGE_USAGE } from "~constants/errors";
import { redirectToOAuthLogin } from "~features/auth";
import { setDefaultErrorToast } from "~features/toast";
import { useUserAgent } from "~features/user-agent";
import { type Result, ResultActions } from "./validators";

type ValidationResultHandlerOptions = {
  withValid: () => void | Promise<void>;
  createOAuthRedirectUrl?: () => string;
  withInvalid?: {
    condition: (result: Result) => boolean;
    handler: (result: Result) => void | Promise<void>;
  }[];
  withOtherwise?: () => void | Promise<void>;
};

export const useRequestValidation = () => {
  const { isWeb } = useUserAgent();
  const { nativeBridge } = useNativeBridge();

  const matchValidationResult = useCallback(
    (validationResult: Result, options: ValidationResultHandlerOptions) => {
      const initMatcher = match(validationResult)
        .with({ isValid: true }, () => {
          options.withValid();
        })
        .with({ isValid: false, action: ResultActions.USER_ID_EMPTY }, () => {
          const nextUrl =
            options.createOAuthRedirectUrl?.() ?? window.location.href;
          isWeb ? redirectToOAuthLogin(nextUrl) : nativeBridge?.requestLogin();
        })
        .with({ isValid: false, action: ResultActions.USER_DISABLED }, () => {
          setDefaultErrorToast("활성화되지 않은 사용자입니다.");
        })
        .with(
          {
            isValid: false,
            action: ResultActions.USER_USAGE_LIMIT_EXCEEDED,
          },
          () => {
            setDefaultErrorToast(ERROR_MESSAGE_USAGE.message);
          },
        );

      return (options.withInvalid ?? [])
        .reduce(
          (matcher, { condition, handler }) => matcher.when(condition, handler),
          initMatcher,
        )
        .otherwise(() => {
          options.withOtherwise?.();
        });
    },
    [isWeb, nativeBridge],
  );

  return {
    matchValidationResult,
  };
};
