import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { type PropsWithChildren, useState } from "react";
import { useIntersectingMessagesContext } from "~features/messages/intersection";
import { MenuBox } from "../popup-menu/menu-box";
import TocItem from "./toc-item";
import type { TocItemData } from "./toc-item.types";
import TocList from "./toc-list";

import styles from "./side-toc.module.scss";

type TocListProps = PropsWithChildren<{
  items: TocItemData[];
  onClickTocItem: (tocItem: TocItemData) => void;
}>;

export const SideToc = ({ items, onClickTocItem }: TocListProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "right",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: -12, crossAxis: 0 }),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
    ],
  });

  const hover = useHover(context, {
    mouseOnly: true,
    delay: { open: 0, close: 300 }, // 300ms 딜레이로 닫힘 처리
    restMs: 10, // 마우스 이동 시 잠시 멈추면 메뉴 유지
  });

  const dismiss = useDismiss(context, {});
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
    role,
  ]);

  const { messageIds } = useIntersectingMessagesContext();

  /** @description result 1개만 있는 경우 미노출 */
  if (items.length < 3) {
    return null;
  }

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className={styles.side_toc}
      >
        <TocList isSimplified={true}>
          {items.map(({ title, messageId, options }) => (
            <TocItem
              key={`toc-simplified-item-${messageId}-isQuestion-${options.isQuestion}`}
              title={title}
              isSimplified={true}
              isQuestion={options?.isQuestion ?? false}
              isFocus={messageIds.has(messageId)}
            />
          ))}
        </TocList>

        {isOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <MenuBox
              ref={refs.setFloating}
              width="232px"
              padding="24px"
              floatingStyles={floatingStyles}
              {...getFloatingProps()}
              overrideClass={styles.table}
            >
              <TocList>
                {items.map((item) => (
                  <TocItem
                    key={`toc-magnified-item-${item.messageId}-isQuestion-${item.options.isQuestion}`}
                    title={item.title}
                    isQuestion={item.options?.isQuestion ?? false}
                    onClickButton={() => {
                      onClickTocItem(item);
                    }}
                  />
                ))}
              </TocList>
            </MenuBox>
          </FloatingFocusManager>
        )}
      </div>
    </>
  );
};

export default SideToc;
