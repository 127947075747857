import { queryOptions, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { INIT_SEARCH_RECOMMENDATIONS, transformResponse } from "./schemas";

export const useQuerySearchRecommends = () => {
  const queryOption = useMemo(
    () => Object.assign({}, searchRecommendsQueryOptions, { retry: 3 }),
    [],
  );

  return useQuery(queryOption);
};

export const searchRecommendsQueryOptions = queryOptions({
  queryKey: QUERY_KEYS.SEARCH.RECOMMENDATIONS,
  queryFn: async () =>
    await KyClient("search/recommend-keywords").json().then(transformResponse),
  refetchInterval: TIMES.MIN * 15,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  retry: false,
  retryDelay(failureCount, _error) {
    return failureCount * TIMES.SEC;
  },
  placeholderData: INIT_SEARCH_RECOMMENDATIONS,
});
