import { create } from "zustand";

type CardStates = {
  openedCards: Map<string, boolean>;
  playtimeCard: string;
};

type CardActions = {
  openCard: (cardId: string) => void;
  toggleCardOpened: (cardId: string) => void;
  clearCardOpened: () => void;
  setPlaytimeCard: (cardId: string) => void;
};

const INIT_STATES: CardStates = {
  openedCards: new Map([]),
  playtimeCard: "",
};

export const useCard = create<CardStates & CardActions>((set) => ({
  ...INIT_STATES,
  openCard: (cardId: string) =>
    set((prev) => {
      const newCards = new Map(prev.openedCards);
      newCards.set(cardId, true);
      return {
        openedCards: newCards,
      };
    }),
  toggleCardOpened: (cardId: string) =>
    set((prevStates) => {
      const newCards = new Map(prevStates.openedCards);
      newCards.has(cardId)
        ? newCards.set(cardId, !newCards.get(cardId))
        : newCards.set(cardId, true);

      return {
        openedCards: newCards,
      };
    }),
  clearCardOpened: () => set({ openedCards: new Map([]) }),
  setPlaytimeCard: (cardId) => set({ playtimeCard: cardId }),
}));
