import {
  type CSSProperties,
  type FocusEvent,
  type KeyboardEvent,
  type PropsWithChildren,
  type RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { match } from "ts-pattern";
import { useWindowWidth } from "~hooks/use-window-width";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import { usePromptContext } from "./context";
import styles from "./form.module.scss";
import { useRawTextContext } from "./raw-text.context";

type PromptContainerProps = PropsWithChildren<{
  className?: string;
}>;

export function PromptFormContainer({
  children,
  className,
}: PromptContainerProps) {
  return <div className={cn(className)}>{children}</div>;
}

type PromptFormProps = PropsWithChildren<{
  className?: string;
}>;

export function PromptForm({ children, className }: PromptFormProps) {
  return <form className={cn(className)}>{children}</form>;
}

type PromptTextareaProps = {
  className?: string;
};

export const PromptTextarea = forwardRef<
  HTMLTextAreaElement,
  PromptTextareaProps
>(function PromptTextarea(props, ref) {
  const rawTextContext = useRawTextContext();
  const promptContext = usePromptContext();

  const { isMobileSmallSize: isMobileSize } = useWindowWidth();
  const [isFocused, setIsFocused] = useState(false);
  const hasLineBreak = /\n/.test(rawTextContext.rawText ?? "");

  const [textareaStyle, setTextareaStyle] = useState<CSSProperties>({});

  const adjustTextareaHeight = useCallback(
    (textarea: HTMLTextAreaElement) => {
      if (!isMobileSize) {
        if (isFocused) {
          const styles: CSSProperties = { height: "auto" };

          // textareaRows가 1일때 텍스트에 개행이 있을 경우에만 높이 조정
          if (promptContext.textareaRows === 1 && hasLineBreak) {
            styles.height = `${textarea.scrollHeight}px`;
            styles.overflowY = "auto";
          }

          setTextareaStyle(styles);
          return;
        }

        setTextareaStyle({
          height: "",
          overflowY: "hidden",
        });
      }
    },
    [isMobileSize, isFocused, promptContext.textareaRows, hasLineBreak],
  );

  useEffect(() => {
    if (isMobileSize) return;

    /** @todo ForwardedRef 내부에서 활용시 타입 추론 */
    if ((ref as RefObject<HTMLTextAreaElement>)?.current) {
      adjustTextareaHeight(
        (ref as RefObject<HTMLTextAreaElement>).current as HTMLTextAreaElement,
      );
    }
  }, [ref, isMobileSize, adjustTextareaHeight]);

  const onKeyDown = createEventHandler<KeyboardEvent>({
    preventDefault: false,
    handler: (e) => {
      match({ key: e.key, shiftKey: e.shiftKey }).with(
        { key: "Enter", shiftKey: false },
        () => {
          e.preventDefault();
        },
      );
    },
  });

  const onFocus = createEventHandler<FocusEvent<HTMLTextAreaElement>>({
    handler: (e) => {
      setIsFocused(true);
      promptContext.onFocusTextarea?.(e);

      if (!isMobileSize) return;

      const textLength = rawTextContext.rawText.length;
      const $textarea = promptContext.textareaRef.current;
      $textarea?.setSelectionRange(textLength, textLength, "none");

      /** @description 모바일 레이어 열리는 동안 scrollHeight 변경 - setTimeout으로 조정 */
      window.setTimeout(() => {
        if ($textarea === null) return;
        $textarea.scrollTop = $textarea?.scrollHeight;
      }, 10);
    },
  });

  const onBlur = createEventHandler<FocusEvent<HTMLTextAreaElement>>({
    handler: (e) => {
      setIsFocused(false);
      if (!isMobileSize) {
        setTextareaStyle({
          overflowY: "hidden",
        });
      }
      promptContext.onBlurTextarea?.(e);
    },
  });

  return (
    <textarea
      ref={ref}
      rows={promptContext.textareaRows ?? 1}
      placeholder={promptContext.placeholderText}
      title={
        rawTextContext.rawText.length > 0
          ? undefined
          : promptContext.placeholderText
      }
      value={rawTextContext.rawText}
      onChange={(e) => {
        promptContext.onChangeTextarea?.(e);
        adjustTextareaHeight(e.target as HTMLTextAreaElement);
      }}
      onKeyDown={onKeyDown}
      onKeyUp={promptContext.onKeyUpTextarea}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={promptContext.onClickTextarea}
      className={cn(
        styles.form_textarea,
        promptContext.textareaRows === 1
          ? hasLineBreak
            ? styles.line_multi // 개행이 있으면 line_multi
            : styles.line_1 // 개행이 없야 line_1
          : styles.line_2, // textareaRows !== 1이면 line_2
        props.className,
      )}
      style={textareaStyle}
      disabled={!promptContext.textareaEnabled}
    />
  );
});
