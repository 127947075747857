import { useCallback, useEffect } from "react";
import { match } from "ts-pattern";
import {
  type NativeMessageCore,
  NativeMessageTypes,
  useNativeBridge,
} from "~clients/native-bridge";
import {
  EnterKeyActionReceivedDataSchema,
  MessageInputReceivedDataSchema,
  MessageSendReceivedDataSchema,
  SetSttActivatedReceivedDataSchema,
  SetVirtualKeyboardActivatedReceivedDataSchema,
} from "~clients/native-bridge/schemas/user-text-message";
import { useSttMode, useVirtualKeyboard } from "~components/chat/message-input";
import { useRawTextContext } from "~features/prompt";
import { v } from "~libs/valibot";
import { logger } from "~utils/logger";

type UseNativeBridgeMessagesArgs = {
  isWeb: boolean;
  isAndroid: boolean;
  sendMessage: () => Promise<void>;
};

/** @todo 파일 이동 */
export const useNativeBridgeMessages = ({
  isWeb,
  isAndroid,
  sendMessage,
}: UseNativeBridgeMessagesArgs) => {
  const { isSttMode, updateIsSttMode } = useSttMode();
  const { setUseVirtualKeyboard } = useVirtualKeyboard();
  const rawTextContext = useRawTextContext();
  const { receivedMessage, resetReceivedMessage } = useNativeBridge();

  const handleIosSttMessage = useCallback(
    (sttMessage: string) => {
      try {
        if (!rawTextContext) return;

        const { rawKeyboardText = "" } = rawTextContext;
        let keyboardText = "";

        if (
          rawKeyboardText === undefined ||
          (typeof rawKeyboardText === "string" && rawKeyboardText.length === 0)
        ) {
          keyboardText = rawTextContext.rawText;
          rawTextContext.setRawKeyboardText!(keyboardText);
        }

        rawTextContext.setRawText(
          [keyboardText, sttMessage]
            .filter((val) => val !== undefined && val !== "")
            .join("\n"),
        );
      } catch (e) {
        logger.error({
          scope: "useNativeBridgeMessages.handleIosSttMessage.error",
          message: (e as Error).message,
        });
      }
    },
    [rawTextContext],
  );

  const messageHandler = useCallback(
    (receivedMessage: NativeMessageCore) =>
      match(receivedMessage)
        .with({ type: NativeMessageTypes.updateIsStt }, () => {
          const parseResult = v.safeParse(
            SetSttActivatedReceivedDataSchema,
            receivedMessage.data,
          );
          if (!parseResult.success) return;

          updateIsSttMode(parseResult.output.isSttActivated);
          /** @todo 없어도 될듯? */
          rawTextContext.setRawSttText?.("");
        })
        .with({ type: NativeMessageTypes.inputMessage }, () => {
          const parseResult = v.safeParse(
            MessageInputReceivedDataSchema,
            receivedMessage.data,
          );
          if (!parseResult.success) return;

          match({ isAndroid, isSttMode, message: parseResult.output.message })
            /**
             * @description
             * - iOS - setStt true 상태에서 전체 sttText 업데이트 될때마다 전체 계속 보냄
             * - 업데이트 되면서 이전 문자열과 달라질 수 잇음
             * @todo
             * - iOS만 STT 상태관리 따로 빼기
             */
            .with(
              { isAndroid: false, isSttMode: true },
              ({ message: sttMessage }) => {
                handleIosSttMessage(sttMessage);
              },
            )
            .with({ isAndroid: false, isSttMode: false }, () => {
              rawTextContext.setRawKeyboardText?.("");
            })
            /** @description Android - setStt false 먼저 보내고 sttText diff 보냄 */
            .with({ isAndroid: true, isSttMode: true }, () => {
              updateIsSttMode(false);
            })
            .with(
              { isAndroid: true, isSttMode: false },
              ({ message: sttMessage }) => {
                rawTextContext.setRawText(
                  rawTextContext.rawText.concat(sttMessage),
                );
                updateIsSttMode(false);
              },
            );
        })
        .with({ type: NativeMessageTypes.sendMessage }, () => {
          const parseResult = v.safeParse(
            MessageSendReceivedDataSchema,
            receivedMessage.data,
          );
          if (!parseResult.success) return;

          sendMessage();
        })
        .with({ type: NativeMessageTypes.enterKeyAction }, () => {
          const parseResult = v.safeParse(
            EnterKeyActionReceivedDataSchema,
            receivedMessage.data,
          );

          logger.debug({
            scope: "useNativeBridgeMessages.messageHandler.enterKeyAction.send",
            data: parseResult,
          });
        })
        .with({ type: NativeMessageTypes.useVirtualKeyboard }, () => {
          const parseResult = v.safeParse(
            SetVirtualKeyboardActivatedReceivedDataSchema,
            receivedMessage.data,
          );
          if (!parseResult.success) return;

          setUseVirtualKeyboard(parseResult.output.isVirtualKeyboardActivated);
        }),
    [
      isAndroid,
      isSttMode,
      updateIsSttMode,
      setUseVirtualKeyboard,
      sendMessage,
      rawTextContext.rawText,
      rawTextContext.setRawText,
      handleIosSttMessage,
    ],
  );

  useEffect(() => {
    if (isWeb) return;

    if (receivedMessage.type !== NativeMessageTypes._empty) {
      messageHandler(receivedMessage);
      /** @todo 이 방법이 최선인가..? */
      resetReceivedMessage();
    }
  }, [isWeb, receivedMessage, messageHandler, resetReceivedMessage]);
};
