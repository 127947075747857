import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";

type ListScrollEventContextStates = {
  enabled: boolean;
  scrollTopRef: React.MutableRefObject<number>;

  distance: number;
  setDistance: Dispatch<SetStateAction<number>>;
};

export const ListScrollEventContext =
  createContext<ListScrollEventContextStates | null>(null);

export const useListScrollEventContext = () => {
  const context = useContext(ListScrollEventContext);
  if (!context) {
    throw new Error(
      "useListScrollEventContext must be used within a ListScrollEventContextProvider",
    );
  }
  return context;
};

type WithListScrollEventContextProps = PropsWithChildren<{ enabled?: boolean }>;

export function WithListScrollEventContext({
  enabled = true,
  children,
}: WithListScrollEventContextProps) {
  const scrollTopRef = useRef(0);
  const [distance, setDistance] = useState(0);

  return (
    <ListScrollEventContext.Provider
      value={{ enabled, scrollTopRef, distance, setDistance }}
    >
      {children}
    </ListScrollEventContext.Provider>
  );
}
