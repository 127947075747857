import { type InferOutput, v } from "~libs/valibot";
import { logger } from "~utils/logger";

/**
 * @example
 * ```json
 * {
    "_id": "65af73b6583a64a91bd6e002",
    "name": "위정훈",
    "detailInfo": {
        "usageLimit": 1000,
        "usageCount": 0
    },
    "enabled": true,
    "waiting": false,
    "isOnboarded": true,
    "expires_in": 5430064
  }
 * ```
 */
export type UserResponse = InferOutput<typeof UserSchema$Response>;

const UserSchema$Response = v.object({
  _id: v.string(),
  name: v.string(),
  detailInfo: v.object({
    usageLimit: v.number(),
    usageCount: v.number(),
  }),
  enabled: v.boolean(),
  waiting: v.boolean(),
  isOnboarded: v.boolean(),
  expires_in: v.number(),
});

export type User = {
  id: string;
  name: string;
  usageLimit: number;
  usageCount: number;
  enabled: boolean;
  waiting: boolean;
  isOnboarded?: boolean;
};

export const UserSchema = v.pipe(
  UserSchema$Response,

  v.transform(
    (response): User => ({
      id: response._id,
      name: response.name,
      usageLimit: response.detailInfo.usageLimit,
      usageCount: response.detailInfo.usageCount,
      enabled: response.enabled,
      waiting: response.waiting,
      isOnboarded: response.isOnboarded ?? undefined,
    }),
  ),
);

export const EMPTY_USER: User = {
  id: "",
  name: "",
  usageLimit: 0,
  usageCount: 0,
  enabled: false,
  waiting: false,
  isOnboarded: undefined,
} as const;

/**
 * @todo error 스키마 추가
 * @example
 * ```json
 * {"detail":{"code":"E1002","message":"Not authenticated"}}
 * ```
 */
export const parseUserResponse = (response: unknown) => {
  try {
    return v.parse(UserSchema, response, {
      abortEarly: true,
    });
  } catch (error) {
    logger.error({
      scope: "UserSchema.parseUserResponse",
      data: { error, response },
    });
    return EMPTY_USER;
  }
};
