import { v } from "~libs/valibot";
import { MESSAGE_STATUS$SERVER } from "../constants";
import { MessagesSchemas$Api } from "./schemas.api";
import { getMessagePattern } from "./shared";

/**
 * @example
 ```json
  {
  
  "data": {
    "id": null,
    "request_id": "dd89316c-bd1a-11ef-891a-2aa816e516d6",
    "persona_id": "673196d6fc73eb2bc3846cc8",
    "channel_id": "6762890773845601d0be84d5",
    "user_id": "65af73b6583a64a91bd6e002",
    "client_id": "80bc13c3-a2ad-49f2-89ec-3c1103e13330",
    "user_role": "assistant",
    "type": "error",
    "stop_reason": null,
    "content": "",
    "actions": [
      {
        "name": "",
        "arguments": {},
        "speak": "질문의 의도를 이해하고 있어요.",
        "created_at": "2024-12-18T08:34:25.219895Z"
      }
    ],
    "references": [],
    "images": [],
    "videos": [],
    "gpt_info": {
      "token": {
        "completion_tokens": {
          "gpt-4o": 20
        },
        "prompt_tokens": {
          "gpt-4o-mini": 93,
          "gpt-4o": 1002
        },
        "total_tokens": {
          "gpt-4o-mini": 93,
          "gpt-4o": 1022
        }
      },
      "question_count": {
        "gpt-4o": 1
      }
    },
    
  }
}
 ```
 */
const ErrorMessageSchema$Socket = v.pipe(
  v.object({
    type: v.literal(MESSAGE_STATUS$SERVER.ERROR),
    request_id: MessagesSchemas$Api.Item.entries.request_id,
  }),

  v.transform((input) => ({
    requestId: input.request_id,
  })),
);

export const parseErrorMessage$Socket = (input: unknown) =>
  v.parse(ErrorMessageSchema$Socket, input, {
    abortEarly: true,
  });

export const ErrorMessagePattern$Socket = getMessagePattern({
  type: MESSAGE_STATUS$SERVER.ERROR,
});
