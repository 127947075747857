import {
  ErrorPopup,
  NoticeModal,
  UpdateModal,
  useErrorInfo,
  useModal,
  useModalVisibleLocal,
} from "~features/modals";
import { UpdateYoutubeImage } from "~features/modals/images";

/** @todo 겹치면? - 단일 모달만 뜨도록 리팩터링 */
export function ModalsCoreLayout() {
  const { hasModalContent } = useModal();
  const { isModalVisible, updateModalVisible } = useModalVisibleLocal();
  const { hasError } = useErrorInfo();

  return (
    <>
      {hasError && <ErrorPopup />}
      {hasModalContent && <NoticeModal />}
      {isModalVisible && (
        <UpdateModal
          title="유튜브, 웹문서 요약 업데이트"
          details={
            "이제 유튜브 링크나 웹문서 링크를 검색창에 입력하면\n바로 요약 결과를 확인할 수 있어요! 🚀"
          }
          isTodayCloseBtn
          img={UpdateYoutubeImage}
          imgWidthSize="274px"
          onClickConfirm={() => {
            updateModalVisible(false);
          }}
        />
      )}
    </>
  );
}
