import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import {
  type SendYoutubeUrlRequest,
  transformSendYoutubeUrlRequest,
} from "./send-youtube-url-options.schema";

const API_PATH = "youtube/summary";

export const sendYoutubeUrl = (request: SendYoutubeUrlRequest) => {
  const transformed = transformSendYoutubeUrlRequest(request);

  return KyClient.post(API_PATH, {
    json: transformed,
    timeout: TIMES.MIN * 1,
    retry: 0,
  });
};
