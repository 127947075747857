import type { CSSProperties, PropsWithChildren } from "react";
import { RelatedSearchQueries } from "~features/prompt/related-search-queries";
import { HomeButton } from "~features/ui/home-button";
import { UsageIndicator } from "~features/usage-indicator";
import { useWindowWidth } from "~hooks/use-window-width";
import { cn } from "~utils/class-names";
import { MenuButtonMobile } from "./menu-button-mobile";

import { useRelatedSearchQueries } from "~features/prompt/related-search-queries/related-search-queries.context";
import styles from "./header.module.scss";

export type HeaderProps = PropsWithChildren<{
  className?: string;
  channelId?: string;
  style?: CSSProperties;
}>;

export function Header({ children, className, style = {} }: HeaderProps) {
  return (
    <header className={cn(styles.header, className)} style={style}>
      {children}
    </header>
  );
}

export function HeaderHome() {
  const { isMobileSmallSize } = useWindowWidth();

  return (
    <Header className={styles.home}>
      <div
        className={cn(isMobileSmallSize ? styles.home_wrap : styles.side_wrap)}
      >
        {isMobileSmallSize && (
          <span className={styles.menu_wrap}>
            <MenuButtonMobile />
          </span>
        )}
        <HomeButton />
      </div>
    </Header>
  );
}

/**
 * @todo 연관검색어 사용하는 것만 분리하는게 나을듯
 */
export function HeaderWithPrompt({ children }: HeaderProps) {
  const { enabled, headerHeight, relatedQueriesStyle } =
    useRelatedSearchQueries();
  const { isMobileSmallSize } = useWindowWidth();

  return (
    <>
      <Header style={{ height: headerHeight }}>
        {isMobileSmallSize ? (
          <>
            <div className={styles.top_wrap}>
              <div className={styles.btn_wrap}>
                <MenuButtonMobile />
                <HomeButton />
              </div>
              <UsageIndicator isFlexEnd={true} className="text-alan-black-5" />
            </div>
            <div className={styles.prompt_wrap}>{children}</div>

            {enabled && (
              <div
                className={styles.related_queries_transition}
                style={relatedQueriesStyle}
              >
                <RelatedSearchQueries />
              </div>
            )}
          </>
        ) : (
          <>
            <div className={styles.top_wrap}>
              <div className={styles.side_wrap}>
                <HomeButton />
              </div>
              <div className={styles.prompt_wrap}>{children}</div>
              <div className={styles.side_wrap}>
                <UsageIndicator isFlexEnd={true} className={styles.indicator} />
              </div>
            </div>

            {enabled && (
              <div
                className={cn(
                  styles.suggestions_wrap,
                  styles.related_queries_transition,
                )}
                style={relatedQueriesStyle}
              >
                <div className={styles.side_wrap} />
                <RelatedSearchQueries />
                <div className={styles.side_wrap} />
              </div>
            )}
          </>
        )}
      </Header>
    </>
  );
}
