import { Button } from "~features/ui/button";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import styles from "./answer-stop-button.module.scss";

type AnswerStopButtonProps = {
  onClick?: () => Promise<void> | void;
  className?: string;
  isEnabled?: boolean;
};

const AnswerStopButton: React.FC<AnswerStopButtonProps> = ({
  onClick,
  className,
  isEnabled = true,
}) => {
  const _onClick = createEventHandler({
    handler: () => {
      onClick?.();
    },
  });

  return (
    <Button
      onClick={_onClick}
      overrideClass={cn(styles.btn_stop, className, {
        [styles.disabled]: !isEnabled,
      })}
      text="답변 중지"
      size="medium"
      variant="line"
      aria-label="answer stop button"
      isDisabled={!isEnabled}
      iconAfter={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <rect x="3" y="3" width="10" height="10" fill="black" />
        </svg>
      }
    />
  );
};

export default AnswerStopButton;
