import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useFirstPromptContext } from "./first-prompt";

type RawTextContextStates = {
  rawText: string;
  setRawText: (rawText: string) => void;

  rawKeyboardText?: string;
  setRawKeyboardText?: (rawKeyboardText: string) => void;

  sttTextEnabled?: boolean;
  rawSttText?: string;
  setRawSttText?: (rawSttText: string) => void;
};

export const RawTextContext = createContext<RawTextContextStates | undefined>(
  undefined,
);

export const useRawTextContext = () => {
  const context = useContext(RawTextContext);
  if (!context) {
    throw new Error("useRawTextContext must be used within a RawTextProvider");
  }
  return context;
};

export function WithRawTextContext({ children }: PropsWithChildren) {
  /** @todo ref로 전부 바꾸기?? */
  const [rawText, setRawText] = useState("");
  const [rawKeyboardText, setRawKeyboardText] = useState("");

  const { firstPrompt } = useFirstPromptContext();
  const rawTextRef = useRef("");

  useEffect(() => {
    if (rawTextRef.current.length > 0) return;

    setRawText(firstPrompt);
    rawTextRef.current = firstPrompt;
  }, [firstPrompt]);

  useEffect(() => {
    rawTextRef.current = rawText;
  }, [rawText]);

  return (
    <RawTextContext.Provider
      value={{
        rawText,
        setRawText,
        rawKeyboardText,
        setRawKeyboardText,
      }}
    >
      {children}
    </RawTextContext.Provider>
  );
}
