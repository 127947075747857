import { ENV_MODE, IS_DEV } from "~constants/env";

type Log = {
  scope: string;
  message?: string;
  data?: Record<string, any>;
};

type LogError = Log & {
  stack?: string;
};

class Logger {
  constructor(
    protected readonly remoteBaseUrl = import.meta.env
      .VITE_DEBUG_REMOTE_BASE_URL ?? "",
    protected readonly debugEnabled = Boolean(
      import.meta.env.VITE_GEPETO_DEBUG === "true",
    ),
  ) {}

  debug(log: Log) {
    if (!this.debugEnabled) return;

    if (IS_DEV) {
      const { scope, message, ...rest } = log;
      console.debug(`[${scope}] ${message ?? ""}\n`, rest);
      return;
    }

    this.postMessage(log);
  }

  error(log: LogError) {
    if (this.debugEnabled && IS_DEV) {
      console.error(log);
      return;
    }

    this.postMessage(log);
  }

  postMessage(log: Log) {
    if (!this.remoteBaseUrl) return;

    fetch(`${this.remoteBaseUrl}?scope=${log.scope}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        Object.assign({}, log, {
          metadata: {
            envMode: ENV_MODE,
            timestamp: new Date().toLocaleString("ko", {
              timeZone: "Asia/Seoul",
            }),
            userAgent: window.navigator.userAgent,
            location: window.location.href,
          },
        }),
      ),
    });
  }
}

export const logger = new Logger();
