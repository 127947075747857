import type { MouseEvent, PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import { Background } from "./background";
import { CloseButton } from "./close-button";
import { Container } from "./container";

type DrawerProps = PropsWithChildren<{
  closeDrawer: () => void;
  className?: string;
}>;

export function Root(props: DrawerProps) {
  const closeDrawer = createEventHandler<MouseEvent>({
    handler: () => {
      props.closeDrawer();
    },
  });

  return (
    <section
      className={cn(
        "z-50 fixed transparent w-full h-full bottom-0",
        props.className,
      )}
    >
      <Background closeDrawer={closeDrawer} />
      <Container>
        <CloseButton closeDrawer={closeDrawer} />

        {props?.children}
      </Container>
    </section>
  );
}
