import type { MouseEventHandler } from "react";
import { cn } from "~utils/class-names";
import styles from "./toc-item.module.scss";

type TocItemProps = {
  isSimplified?: boolean;
  isFocus?: boolean;
  isQuestion?: boolean;
  url?: string;
  title?: string;
  onClickButton?: MouseEventHandler;
};

const TocItem: React.FC<TocItemProps> = ({ ...props }) => {
  return (
    <div
      className={cn(styles.toc_item, {
        [styles.simple]: props.isSimplified,
        [styles.focus]: props.isFocus,
        [styles.question]: props.isQuestion,
      })}
    >
      {!props.isSimplified && (
        <div onClick={props.onClickButton} className={styles.link}>
          {props.isQuestion ? (
            <div className={styles.question_wrap}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path d="M9 6L12 9L9 12" stroke="#8C8F95" />
                <path d="M5 3V9H11.5" stroke="#8C8F95" />
              </svg>
              <span>관련된 질문</span>
            </div>
          ) : (
            props.title
          )}
        </div>
      )}
    </div>
  );
};

export default TocItem;
