import { type MutableRefObject, useCallback, useEffect } from "react";
import { P, match } from "ts-pattern";
import { useListScrollEventContext } from "./list-scroll-event.context";

type UseListScrollEventArgs = {
  scrollElementRef: MutableRefObject<HTMLDivElement | null>;
};

export const useListScrollEvent = ({
  scrollElementRef,
}: UseListScrollEventArgs) => {
  const { enabled, scrollTopRef, setDistance, distance } =
    useListScrollEventContext();

  const scrollHandler = useCallback(
    (e: Event) => {
      if (!e.currentTarget) return;
      // @ts-ignore
      const scrollTop = e.currentTarget.scrollTop ?? 0;
      if (scrollTop === 0) {
        scrollTopRef.current = 0;
        setDistance(0);
        return;
      }

      const diff = scrollTopRef.current - scrollTop;
      const isDown = diff < 0;
      const nextDistance = distance + (isDown ? -15 : 15);

      scrollTopRef.current = scrollTop;

      match({ nextDistance })
        .with({ nextDistance: P.number.gte(0) }, () => {
          setDistance(0);
        })
        .with({ nextDistance: P.number.lt(-30) }, () => {
          setDistance(-30);
        })
        .otherwise(() => {
          setDistance(nextDistance);
        });
    },
    [scrollTopRef, setDistance, distance],
  );

  useEffect(() => {
    if (!enabled) return;

    const $list = scrollElementRef.current;
    if (!$list) return;

    $list.addEventListener("scroll", scrollHandler);

    return () => {
      $list.removeEventListener("scroll", scrollHandler);
    };
  }, [enabled, scrollElementRef.current, scrollHandler]);
};
