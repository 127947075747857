import { useQueryClient } from "@tanstack/react-query";
import throttle from "just-throttle";
import {
  type ChangeEvent,
  type FocusEvent,
  type KeyboardEvent,
  type MouseEvent,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { P, match } from "ts-pattern";
import { useUser } from "~features/auth";
import { useChannel } from "~features/channel";
import { Completions } from "~features/completions";
import { usePromptCompletions } from "~features/completions/use-prompt-completions";
import {
  useMutationCancelMessage,
  useNativeBridgeMessages,
} from "~features/messages/hooks";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { useSendSearchTitle } from "~features/search";
import type { SearchResult } from "~features/search/schemas";
import { useSidebar } from "~features/ui/side-bar";
import { useUserAgent } from "~features/user-agent";
import { Status } from "~features/util-types/status";
import { useWindowWidth } from "~hooks/use-window-width";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import { IconVariants, PromptIcon } from "./icons";
import { Prompt, useRawTextContext } from "./index";
import {
  useCreateChannelAndNavigate,
  useMutationSendPromptWithNewChannel,
  useRequestValidation,
  validateRequestWithNewChannel,
} from "./mutations";
import { usePlaceholderWithRecommends } from "./use-placeholder-with-recommends";
import { categorizePrompt } from "./utils";

import styles from "./prompt.module.scss";

const MIN_TEXTAREA_HEIGHT = 46;
const MIN_TEXTAREA_PADDING = 7;

type PromptWithCompletionProps = PropsWithChildren<{
  className?: string;
  height?: string;
  focusOnMount?: boolean;
  onFocusTextarea?: () => void;
  beforeSendPrompt?: () => void | Promise<void>;
  afterSendPrompt?: () => void | Promise<void>;
}>;

/**
 * @TODO: Container-Presenter 분리해야 되나..
 */
export function PromptWithCompletion({
  className,
  height,
  children,
  ...props
}: PromptWithCompletionProps) {
  const { user } = useUser();
  const { rawText, setRawText } = useRawTextContext();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [rows, setRows] = useState(1);
  const { isMobileSmallSize: isMobileSize } = useWindowWidth();

  const { placeholderText } = usePlaceholderWithRecommends(rawText);

  const { matchValidationResult } = useRequestValidation();
  const { createChannelAndNavigate } = useCreateChannelAndNavigate();
  const { mutate } = useMutationSendPromptWithNewChannel(user);
  const { requestNativeNotificationPermission } = useSendSearchTitle();

  const cancelSendingMutation = useMutationCancelMessage();

  const {
    isCompletionsVisible,
    setIsCompletionsVisible,
    setKeyword,
    completions,
  } = usePromptCompletions();
  const [focusedIndex, setFocusedIndex] = useState(-1);

  const { channelId } = useChannel();
  const queryClient = useQueryClient();
  const searchResults = queryClient.getQueryData<SearchResult.Page>(
    QUERY_KEYS.CHANNELS.getChannelMessagesQueryKey(channelId),
  );

  const iconVariant = useMemo(() => {
    const { hasUrl, hasYoutubeUrl } = categorizePrompt(rawText.trim());
    return match({ hasUrl, hasYoutubeUrl, isCompletionsVisible, isMobileSize })
      .with(
        { isMobileSize: true, isCompletionsVisible: true },
        () => IconVariants.Init,
      )
      .with({ hasYoutubeUrl: true }, () => IconVariants.Youtube)
      .with({ hasUrl: true }, () => IconVariants.Url)
      .otherwise(() => IconVariants.Search);
  }, [rawText, isCompletionsVisible, isMobileSize]);

  const { setIsOpen } = useSidebar();

  useEffect(() => {
    props.focusOnMount && textareaRef.current?.focus();
  }, [props.focusOnMount]);

  const sendPrompt = async (prompt = "") => {
    textareaRef.current?.blur();

    const _prompt = (prompt || rawText || placeholderText).trim();
    const categorized = categorizePrompt(_prompt);

    /** @todo 여기서 user query fetch? */
    const validationResult = validateRequestWithNewChannel({
      prompt: _prompt,
      user,
    });

    return matchValidationResult(validationResult, {
      withValid: () =>
        createChannelAndNavigate({
          isForYoutubeSummary: categorized.hasYoutubeUrl,
        }).then(({ channelId, personaId, isForYoutubeSummary }) => {
          setIsOpen(false);

          mutate({
            prompt: _prompt,
            channelId,
            personaId,
            isForYoutubeSummary,
            youtubeId: categorized.youtubeId,
          });

          props.afterSendPrompt?.();

          requestNativeNotificationPermission();
        }),

      createOAuthRedirectUrl: () => {
        const nextUrl = new URL(window.location.origin.concat("/search"));
        nextUrl.searchParams.set("q", _prompt);
        return nextUrl.href;
      },
    });
  };

  const { isMobile: isMobileApp, isWeb, isAndroid } = useUserAgent();

  useNativeBridgeMessages({
    isWeb,
    isAndroid,
    sendMessage: sendPrompt,
  });

  const throttleSetKeyword = useCallback(
    throttle(
      (keyword: string) => {
        setKeyword(keyword);
      },
      300,
      { leading: false },
    ),
    [],
  );

  const onChangeTextarea = createEventHandler<ChangeEvent<HTMLTextAreaElement>>(
    {
      handler: (e) => {
        const rawValue = e.currentTarget.value;
        setRawText(rawValue);

        setFocusedIndex(-1);
        const trimmedValue = rawValue.trim();
        if (rawValue.includes("\n") || trimmedValue.length === 0) {
          throttleSetKeyword("");
          return;
        }

        throttleSetKeyword(trimmedValue);
      },
    },
  );

  const onKeyUpTextarea = createEventHandler<
    KeyboardEvent<HTMLTextAreaElement>
  >({
    preventDefault: false,
    handler: (e) => {
      const {
        key,
        shiftKey,
        nativeEvent: { isComposing },
      } = e;

      match({ key, shiftKey, focusedIndex, isComposing })
        .with({ isComposing: true }, { shiftKey: true }, () => {
          return;
        })
        .with({ key: "Enter", focusedIndex: -1 }, () => {
          e.preventDefault();
          sendPrompt();
          setIsCompletionsVisible(false);
        })
        .with({ key: "Enter" }, () => {
          e.preventDefault();
          const value = completions[focusedIndex].original;
          setRawText(value);
          setKeyword(value);
          setFocusedIndex(-1);
          sendPrompt(value);
        })
        .with(
          {
            key: "ArrowDown",
            focusedIndex: P.number.between(-1, completions.length - 2),
          },
          () => {
            e.preventDefault();
            setFocusedIndex((prev) => prev + 1);
          },
        )
        .with(
          {
            key: "ArrowUp",
            focusedIndex: P.number.between(0, completions.length - 1),
          },
          () => {
            e.preventDefault();
            setFocusedIndex((prev) => prev - 1);
          },
        );
    },
  });

  const onFocusTextarea = createEventHandler<FocusEvent<HTMLTextAreaElement>>({
    preventDefault: false,
    handler: () => {
      props.onFocusTextarea?.();
      !isCompletionsVisible && setIsCompletionsVisible(true);
      isMobileSize && setRows(2);
    },
  });

  const onBlurTextarea = createEventHandler<FocusEvent<HTMLTextAreaElement>>({
    handler: (_e) => {
      if (isMobileSize) return;
      if (!isCompletionsVisible) return;

      /** @todo 명시적으로 닫는 액션 필요 */
      window.setTimeout(() => {
        setIsCompletionsVisible(false);
      }, 250);
    },
  });

  const onClickTextarea = createEventHandler<MouseEvent<HTMLTextAreaElement>>({
    handler: () => {
      // todo
    },
  });

  const setKeywordAsTextValue = (original: string) => {
    setRawText(original);
    setKeyword(original);
  };

  const cancelPrompt = useCallback(async () => {
    const currentRespondingId = searchResults?.items
      .slice(-2)
      .find(
        ({ status }) => status === Status.INIT || status === Status.LOADING,
      )?.requestId;
    if (!currentRespondingId) return;

    cancelSendingMutation.mutate(currentRespondingId);
  }, [cancelSendingMutation, searchResults]);

  const promptFormPadding =
    height && Number.parseInt(height, 10) > MIN_TEXTAREA_HEIGHT
      ? `${(Number.parseInt(height, 10) - MIN_TEXTAREA_HEIGHT) / 2 + MIN_TEXTAREA_PADDING}px`
      : `${MIN_TEXTAREA_PADDING}px`;

  return (
    <Prompt.Root
      context={{
        textareaEnabled: true,
        placeholderText,
        sendPrompt,
        cancelPrompt,
        textareaRef,
        onChangeTextarea,
        onKeyUpTextarea,
        onFocusTextarea,
        onBlurTextarea,
        onClickTextarea,
        textareaRows: rows,
        isMobileSize,
        isSttEnabled: isMobileApp,
      }}
      className={cn(
        styles.prompt_wrapper,
        isCompletionsVisible && styles.h_full,
        className,
      )}
    >
      <Prompt.FormContainer
        className={cn(
          styles.prompt_container,
          !isMobileSize && styles.border_gradient,
        )}
      >
        <Prompt.Form
          className={cn(
            styles.prompt_form_wrapper,
            isMobileSize && styles.border_gradient,
          )}
        >
          <div
            className={cn(
              styles.prompt_form,
              isCompletionsVisible && styles.prompt_focusing,
              isCompletionsVisible &&
                rawText.length > 0 &&
                completions.length > 0 &&
                styles.prompt_border,
            )}
            style={{
              minHeight: height || `${MIN_TEXTAREA_HEIGHT}px`,
              paddingTop: promptFormPadding,
              paddingBottom: promptFormPadding,
            }}
          >
            {iconVariant !== IconVariants.Init && (
              <PromptIcon variants={iconVariant} />
            )}

            <Prompt.Textarea
              ref={textareaRef}
              className={cn(styles.prompt_textarea)}
            />
            <Prompt.Buttons />
          </div>
        </Prompt.Form>

        {isCompletionsVisible &&
          rawText.length > 0 &&
          completions.length > 0 && (
            <div className={styles.completions_wrapper}>
              <Completions.Root
                context={{
                  isMobileSize,
                  setKeywordAsTextValue,
                  textareaRef,
                }}
              >
                {completions.map((completion, idx) => (
                  <Completions.Item
                    key={`completion-${completion.original}`}
                    completion={completion}
                    className={cn(
                      idx === focusedIndex && styles.completion_focusing,
                    )}
                  />
                ))}
              </Completions.Root>
            </div>
          )}
      </Prompt.FormContainer>

      {children}
    </Prompt.Root>
  );
}
