import type { SearchResult } from "~features/search/schemas";
import type { TocItemData } from "./toc-item.types";

export const getTocItems = (results: SearchResult.Item[]): TocItemData[] =>
  results.reduce(
    ({ order, data }, { title, questions, messageId }) => {
      const nextOrder = order + 1;
      const nextData = data.concat({
        title,
        messageId: messageId?.user ?? "",
        order: nextOrder,
        options: { isQuestion: false },
      });

      if (questions) {
        nextData.push({
          title: "",
          messageId: messageId?.user ?? "",
          order: nextOrder,
          options: { isQuestion: true },
        });
      }

      return {
        order: nextOrder,
        data: nextData,
      };
    },
    {
      order: -1,
      data: [] as TocItemData[],
    },
  ).data;
