import { MESSAGE_USER_ROLE } from "~features/messages/constants";
import { MessagesSchemas$Api } from "~features/messages/schemas";
import { Status } from "~features/util-types/status";
import { v } from "~libs/valibot";
import type { SearchResult } from "./search-results.types";
import {
  transformImages,
  transformInitResult,
  transformQuestions,
  transformStatusWithContent,
  transformSteps,
  transformVideos,
  transformWebSources,
} from "./transform.utils";

const EMPTY_ITEMS: SearchResult.Page = {
  items: [],
  total: 0,
} as const;

/** from api - message list */
const SearchResultsSchema$ApiTransformed = v.pipe(
  MessagesSchemas$Api.List,

  v.transform((res): SearchResult.Page => {
    if (res.messages.length === 0) {
      return EMPTY_ITEMS;
    }

    const firstMessage = res.messages[0];
    const originPersonaId = firstMessage.persona_id ?? "";
    const filteredItems = res.messages.filter(
      (m) => m.persona_id === originPersonaId,
    );

    const lastIndex = filteredItems.length - 1;
    const items = filteredItems.reduce<SearchResult.Item[]>(
      (items, item, idx): SearchResult.Item[] => {
        const isUserMessage = item.userRole === MESSAGE_USER_ROLE.USER;
        if (isUserMessage) {
          return items.concat(transformInitResult(item));
        }

        const message = items.find(
          (it) => it.requestId === item.request_id,
        );
        if (!message || message.status === Status.ERROR) {
          return items;
        }

        // TODO: messageId로 통일
        message.id = item._id;
        message.messageId = {
          assistant: item._id,
          user: message.messageId?.user ?? "",
        };

        const { status, statusMessage } = transformStatusWithContent(
          item.type,
          item.stop_reason,
        );
        message.status = status;
        message.toolSteps = transformSteps(
          item.actions,
          message.status,
        );

        message.content =
          status === Status.ERROR ? statusMessage : item.content;
        message.questions =
          (lastIndex !== idx || message.status === Status.ERROR)
            ? undefined
            : transformQuestions(item.suggest_questions);

        if (item.references) {
          message.sources = transformWebSources(item.references);
        }
        if (item.videos) {
          message.videos = transformVideos(item.videos);
        }
        if (item.images) {
          message.images = transformImages(item.images);
        }
        return items;
      },
      [],
    );

    return {
      items,
      total: items.length,
    };
  }),
);

export const parseSearchResultsFromApi = (input: unknown) => {
  const parsed = v.safeParse(SearchResultsSchema$ApiTransformed, input, {
    abortEarly: true,
  });
  if (!parsed.success) {
    console.error("[ERROR::SearchResultSchemas.parseFromApi]\n", parsed.issues);
    return EMPTY_ITEMS;
  }

  return parsed.output;
};
