import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext } from "react";
import type { SearchResult } from "~features/search/schemas";
import type { Status } from "~features/util-types/status";

type StreamingContextStates = {
  setStatus: Dispatch<SetStateAction<Status>>;
  content: string;
  setContent: Dispatch<SetStateAction<string>>;
  toolSteps: SearchResult.ToolSteps[];
  setToolSteps: Dispatch<SetStateAction<SearchResult.ToolSteps[]>>;
};

export const StreamingContext = createContext<StreamingContextStates | null>(
  null,
);

export const useStreamingContext = () => {
  const context = useContext(StreamingContext);
  if (!context) {
    throw new Error(
      "useStreamingContext must be used within a StreamingProvider",
    );
  }
  return context;
};
