import { CancelledError } from "@tanstack/react-query";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { useNativeBridge } from "~clients/native-bridge";
import { TIMES } from "~constants/times";
import { EMPTY_USER, userQueryOptions } from "~features/auth";
import { getChannelQueryOptions } from "~features/channel";
import { personasQueryOptions } from "~features/personas";
import { getSearchQueryOptions } from "~features/search";
import { setDefaultErrorToast } from "~features/toast";
import { useUserAgent } from "~features/user-agent";
import { v } from "~libs/valibot";
import { logger } from "~utils/logger";

const SearchResultSearchSchema = v.object({
  message_id: v.optional(v.string()),
});

export const Route = createFileRoute("/search/$channelId")({
  validateSearch: SearchResultSearchSchema,

  beforeLoad: async ({ context: { queryClient }, params: { channelId } }) => {
    if (
      !useUserAgent.getState().isWeb &&
      !useNativeBridge.getState().nativeBridge
    )
      return { isLoggedIn: false };

    return await queryClient
      .ensureQueryData(userQueryOptions)
      .catch((error) => {
        if (error instanceof CancelledError) {
          return EMPTY_USER;
        }

        window.setTimeout(() => {
          setDefaultErrorToast("로그인이 필요합니다.");

          logger.error({
            scope: "SearchResultChannelRoute.beforeLoad",
            message: error.message,
            stack: error.stack,
          });
        }, TIMES.SEC);

        throw redirect({
          to: "/",
          replace: false,
        });
      })
      .then((result) => {
        return { isLoggedIn: result.id.length > 0 };
      });
  },

  loader: async ({
    context: { queryClient, isLoggedIn },
    params: { channelId },
  }) => {
    if (!isLoggedIn) return;

    try {
      Promise.all([
        queryClient.prefetchQuery(personasQueryOptions),
        queryClient.prefetchQuery(getChannelQueryOptions(channelId)),
        queryClient.prefetchQuery(getSearchQueryOptions(channelId)),
      ]);
    } catch (error: any) {
      logger.error({
        scope: "SearchResultChannelRoute.loader",
        message: error.message,
        stack: error.stack,
      });
    }
  },

  onError: (error) => {
    logger.error({
      scope: "SearchResultChannelRoute.onError",
      message: error.message,
      stack: error.stack,
    });
  },
});
