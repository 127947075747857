import type { PropsWithChildren } from "react";
import { useWebSourcesContext } from "~features/messages/web-sources";
import { useWebSourceDrawer } from "~features/search/result";
import type { SearchResult } from "~features/search/schemas";
import { HoverTooltip } from "~features/ui/tooltip";
import { useWindowWidth } from "~hooks/use-window-width";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";

import styles from "./link.module.scss";

type MarkdownLinkProps = PropsWithChildren<{ href: string }>;

const isWebSourceRegex = /\(출처\s?(\d+)\)/i;

export function MarkdownLink({ children, href }: MarkdownLinkProps) {
  if (!children)
    return (
      <a href={href} target="_blank" rel="noreferrer">
        {href}
      </a>
    );

  /** @description 생성중 일때 그냥 ReactElement로 들어옴 */
  if (!Array.isArray(children)) {
    return children;
  }

  const firstElement = children[0];
  const isWebSource =
    typeof firstElement === "string" && isWebSourceRegex.test(firstElement);

  if (!isWebSource) {
    return (
      <a href={href} target="_blank" rel="noreferrer" className={styles.link}>
        {children}
      </a>
    );
  }

  const [, sourceId] = firstElement.match(isWebSourceRegex) ?? [];

  return <MarkdownLinkWebSource href={href} sourceId={sourceId} />;
}

type MarkdownLinkWebSourceProps = { href: string; sourceId?: string };

function MarkdownLinkWebSource({ href, sourceId }: MarkdownLinkWebSourceProps) {
  const { isMobileSmallSize } = useWindowWidth();
  const { webSources } = useWebSourcesContext();

  if (sourceId === undefined) {
    return null;
  }

  const source = webSources.get(sourceId);
  if (!source) {
    return null;
  }

  if (isMobileSmallSize) {
    return (
      <MobileWebSourceButton
        href={href}
        source={source}
        sourceId={sourceId ?? source.id ?? ""}
      />
    );
  }

  return (
    <HoverTooltip
      link={href}
      source={source}
      className={styles.tooltip_wrapper}
    >
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={cn(styles.link, styles.btn_source)}
      >
        {sourceId ? `출처 ${sourceId}` : source?.id ? `출처 ${source?.id}` : ""}
      </a>
    </HoverTooltip>
  );
}

function MobileWebSourceButton({
  href,
  source,
  sourceId,
}: MarkdownLinkWebSourceProps & { source?: SearchResult.WebSource }) {
  return (
    <MobileWebSourceDrawerButton
      href={href}
      source={source}
      sourceId={sourceId}
    />
  );
}

function MobileWebSourceDrawerButton({
  source,
  sourceId,
}: MarkdownLinkWebSourceProps & { source?: SearchResult.WebSource }) {
  const { selectedWebSource, selectWebSource, setIsOpen } =
    useWebSourceDrawer();

  const onClick = createEventHandler({
    handler: () => {
      setIsOpen(true);
      source && selectWebSource(source);
    },
  });

  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(styles.link, styles.btn_source, {
        [styles.on]: selectedWebSource?.id === sourceId,
      })}
    >
      {sourceId ? `출처 ${sourceId}` : ""}
    </button>
  );
}
