import { useCallback } from "react";
import { useUser } from "~features/auth";
import { useUserAgent } from "~features/user-agent";
import { cn } from "~utils/class-names";
import {
  useCreateChannelAndNavigate,
  useMutationSendPromptWithNewChannel,
  useRequestValidation,
  validateRequestWithNewChannel,
} from "../mutations";
import { useRelatedSearchQueries } from "./related-search-queries.context";

import styles from "./related-search-queries.module.scss";

export function RelatedSearchQueries() {
  const { relatedQueries } = useRelatedSearchQueries();
  const { isMobile } = useUserAgent();

  const { user } = useUser();
  const { matchValidationResult } = useRequestValidation();
  const { mutate } = useMutationSendPromptWithNewChannel(user);
  const { createChannelAndNavigate } = useCreateChannelAndNavigate();

  const sendCompletion = useCallback(
    (completion: string) => {
      const validationResult = validateRequestWithNewChannel({
        prompt: completion,
        user,
      });

      return matchValidationResult(validationResult, {
        withValid: () => {
          createChannelAndNavigate({ isForYoutubeSummary: false }).then(
            ({ channelId, personaId }) => {
              mutate({
                prompt: completion,
                channelId,
                personaId,
                isForYoutubeSummary: false,
              });
            },
          );
        },
      });
    },
    [user, matchValidationResult, mutate, createChannelAndNavigate],
  );

  return (
    <div
      className={cn(styles.completions_wrap, {
        [styles.invisible_scrollbar]: isMobile,
      })}
    >
      <div className={styles.title}>연관검색어</div>
      <ul className={styles.completions}>
        {relatedQueries.map(({ original }) => (
          // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
          <li
            className={styles.completion_item}
            key={`completion-${original}`}
            onClick={() => sendCompletion(original)}
          >
            {original}
          </li>
        ))}
      </ul>
    </div>
  );
}
