import { P, match } from "ts-pattern";
import { noop } from "~utils/noop";
import { MESSAGE_TYPE } from "./constants";

/**
 * @todo error
 * ```json
 * {
  "type": "ack",
  "ackId": 1730797816777,
  "success": false,
  "error": {
    "name": "Forbidden",
    "message": "The client does not have permission to join group '6729afcaacb09805dd93248a'."
  }
}
 * ```
 */
const CORE_MESSAGE_PATTERNS = {
  CONNECTED: {
    type: MESSAGE_TYPE.SYSTEM,
    event: "connected",
  },
  ACK_SUCCESS: {
    type: MESSAGE_TYPE.ACK,
    success: true,
  },
  ACK_FALSE: {
    type: MESSAGE_TYPE.ACK,
    success: false,
    error: P.optional({
      name: P.string,
      message: P.string,
    }),
  },
} as const;

const createJoinMessage = (channelId: string) => ({
  type: MESSAGE_TYPE.JOIN_GROUP,
  group: channelId,
  /**
   * - 동일한 값 보내면 이미 중복된 id로 생성되어 있다는 경고(?) 메시지 옴, 종료되지는 않음
   */
  ackId: new Date().getTime(),
});

type CreateSocketMessageHandlerArgs = {
  sendJsonMessage: (message: unknown) => void;
  channelId: string;
};

export const createSocketMessageHandler =
  ({ sendJsonMessage, channelId }: CreateSocketMessageHandlerArgs) =>
  (lastMessage: unknown) =>
    match(lastMessage)
      .with(CORE_MESSAGE_PATTERNS.CONNECTED, () => {
        sendJsonMessage(createJoinMessage(channelId));
      })
      .with(CORE_MESSAGE_PATTERNS.ACK_FALSE, (_message) => {
        // TODO: reconnect
      })
      .with(CORE_MESSAGE_PATTERNS.ACK_SUCCESS, noop)
      .with(null, noop);
