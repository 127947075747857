import { queryOptions, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { type InferOutput, v } from "~libs/valibot";
import type { Completions } from "./types";

type UseCompletionsArgs = {
  keyword: string;
};

export const usePromptCompletions = () => {
  const [keyword, setKeyword] = useState("");
  const { data = [], isSuccess } = useQueryCompletions({
    keyword,
  });
  const [isCompletionsVisible, setIsCompletionsVisible] = useState(false);
  const [completionsToShow, setCompletionsToShow] = useState(data);

  useEffect(() => {
    if (isSuccess) {
      setCompletionsToShow(data.slice(0, 5));
    }
  }, [data, isSuccess]);

  return {
    keyword,
    setKeyword,
    isCompletionsVisible,
    setIsCompletionsVisible,
    completions: keyword.length > 0 ? completionsToShow : [],
  };
};

export const useQueryCompletions = (args: UseCompletionsArgs) => {
  return useQuery(getCompletionsQueryOptions(args));
};

const API_PATH = "search/auto-complete";

const getCompletionsQueryOptions = ({ keyword }: UseCompletionsArgs) =>
  queryOptions({
    enabled: keyword.length > 0,
    queryKey: QUERY_KEYS.SEARCH.getCompletionQueryKey(keyword),
    queryFn: () =>
      fetchCompletions(keyword)
        .then(parseCompletions)
        .then((completions) => transformCompletions(keyword, completions)),
    staleTime: TIMES.SEC * 5,
    gcTime: TIMES.MIN * 1,
    retry: false,
  });

const fetchCompletions = async (keyword: string) => {
  const response = await KyClient.get(API_PATH, {
    searchParams: { keyword },
  });

  if (!response.ok) {
    return [];
  }

  return response.json();
};

const CompletionSchema$Api = v.object({
  keywords: v.array(v.string()),
});

const CompletionSchema = v.pipe(
  CompletionSchema$Api,
  v.transform((data) => data.keywords),
);

const parseCompletions = (input: unknown) =>
  v.parse(CompletionSchema, input, { abortEarly: true });

const transformCompletions = (
  keyword: string,
  completions: InferOutput<typeof CompletionSchema>,
): Completions.List =>
  completions.map((completion) => {
    const regex = new RegExp(
      Array.from(keyword)
        .filter((char) => char.trim().length > 0)
        .join("\\s*"),
      "i",
    );
    const matched = completion.match(regex)?.[0] ?? "";

    if (matched.length === 0) {
      return { prev: "", matched: "", next: "", original: completion };
    }

    const [prev, next] = completion.split(matched);
    return { prev, matched, next, original: completion };
  });
