import type { QueryClient } from "@tanstack/react-query";
import {
  ErrorComponent,
  Navigate,
  createRootRouteWithContext,
  redirect,
} from "@tanstack/react-router";
import { IS_DEV } from "~constants/env";
import { useUserAgent } from "~features/user-agent";
import ServerErrorLayout from "~layouts/error/server-error";
import { configQueryOptions } from "~layouts/maintenance";
import { RootLayout } from "~layouts/root";
import { logger } from "~utils/logger";

import "~styles/index.scss";

export const Route = createRootRouteWithContext<{ queryClient: QueryClient }>()(
  {
    component: RootLayout,

    // TODO: 404 컴포넌트
    notFoundComponent: () => <Navigate to="/" />,

    onError: (error) => {
      logger.error({
        scope: "RootRoute.onError",
        message: error.message,
        stack: error.stack,
      });
    },

    errorComponent: (props) => {
      if (IS_DEV) {
        return <ErrorComponent error={props.error} />;
      }

      logger.error({
        scope: "RootRoute.errorComponent",
        message: props.error.message,
        stack: props.error.stack,
      });

      return <ServerErrorLayout />;
    },

    beforeLoad: () => {
      return useUserAgent.getState();
    },

    loader: async ({ context, location }) => {
      if (!context.isWeb) return;

      const { halt } =
        await context.queryClient.ensureQueryData(configQueryOptions);

      if (location.pathname !== "/maintenance" && halt.isInHalt) {
        throw redirect({ to: "/maintenance" });
      }
    },
  },
);
