import { useQueryClient } from "@tanstack/react-query";
import { Outlet } from "@tanstack/react-router";
import { useEffect } from "react";
import { match } from "ts-pattern";
import {
  NativeErrorMessageTypes,
  NativeMessageTypes,
  createNativeBridge,
  useNativeBridge,
} from "~clients/native-bridge";
import {
  ERROR_UNKNOWN_PROTOCOL,
  REQUIRE_ALMANAGER,
  REQUIRE_LOGIN,
} from "~constants/errors";
import { TIMES } from "~constants/times";
import { useAccessTokenStore } from "~features/auth";
import { useErrorInfo } from "~features/modals";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { useReload } from "~features/reload";
import { useUserAgent } from "~features/user-agent";
import { ModalsCoreLayout } from "./modals/modals-core-layout";

/** @todo native 로직 분리 */
export function RootLayout() {
  const { isWeb, isAndroid, isIos, isWindows } = useUserAgent();
  const { nativeBridge, setNativeBridge, setReceivedMessage } =
    useNativeBridge();
  const { accessToken, setAccessToken } = useAccessTokenStore();
  const queryClient = useQueryClient();

  const { setErrorDetail, resetErrorDetail } = useErrorInfo();
  const { lastUpdatedTime, updateTime } = useReload();

  /** @todo container 분리 */
  useEffect(() => {
    if (isWeb || nativeBridge) return;

    const bridge = createNativeBridge({ isAndroid, isIos, isWindows });
    if (!bridge) return;

    setNativeBridge(bridge);

    window.handleNativeMessage = (message = "") => {
      if (!message) return;

      const parsedMessage = bridge.parseNativeMessage(message);
      if (!parsedMessage) return;

      /** @todo useCallback으로 분리 */
      match(parsedMessage)
        .with(
          { hasError: true, errorType: NativeErrorMessageTypes.noLogin },
          () => {
            setAccessToken({
              accessToken: "",
              tokenType: "",
              expiresIn: 0,
            });
            setErrorDetail(REQUIRE_LOGIN);
          },
        )
        .with(
          { hasError: true, errorType: NativeErrorMessageTypes.noManager },
          () => {
            setAccessToken({
              accessToken: "",
              tokenType: "",
              expiresIn: 0,
            });
            setErrorDetail(REQUIRE_ALMANAGER);
          },
        )
        .with({ hasError: true }, () => {
          setErrorDetail(ERROR_UNKNOWN_PROTOCOL);
        })
        .with({ type: NativeMessageTypes.setForeground }, () => {
          Date.now() - lastUpdatedTime >= TIMES.MIN * 30
            ? window.location.reload()
            : updateTime();
        })
        .otherwise(() => {
          setReceivedMessage(parsedMessage);
          bridge.runCallback(parsedMessage);
        });
    };
  }, [
    isWeb,
    isAndroid,
    isIos,
    isWindows,
    nativeBridge,
    setNativeBridge,
    setReceivedMessage,
    setErrorDetail,
    updateTime,
    lastUpdatedTime,
    setAccessToken,
  ]);

  useEffect(() => {
    if (!accessToken) return;

    queryClient.cancelQueries({ queryKey: QUERY_KEYS.AUTH.USER }).then(() => {
      queryClient
        .invalidateQueries({ queryKey: QUERY_KEYS.AUTH.USER })
        .then(() => {
          resetErrorDetail();
        });
    });
  }, [accessToken, queryClient, resetErrorDetail]);

  return (
    <div className="root_wrapper">
      <Outlet />

      <ModalsCoreLayout />
    </div>
  );
}
