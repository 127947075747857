import { match, P } from "ts-pattern";
import createSafeUrlRegex from "url-regex-safe";

const urlRegex = createSafeUrlRegex({
  exact: false,
  strict: false,
  localhost: false,
  parens: true,
  apostrophes: true,
  trailingPeriod: true,
  ipv4: true,
  ipv6: true,
});

const EMPTY_URL_MATCH_RESULT = {
  hasUrls: false,
  matches: [],
} as const;

export const getMatchedUrls = (prompt: string) => {
  const matches = prompt.match(urlRegex);
  if (!matches) {
    return EMPTY_URL_MATCH_RESULT;
  }

  return {
    hasUrls: true,
    matches,
  };
};

type YoutubeUrlMatched = {
  youtubeUrl: string;
  videoId: string;
};

const EMPTY_YOUTUBE_URL_MATCH_RESULT = {
  hasYoutubeUrls: false,
  matched: [] as YoutubeUrlMatched[],
} as const;

const YOUTUBE_ORIGINS = {
  DEFAULT: "youtube.com",
  SHORT: "youtu.be",
  NO_COOKIE: "youtube-nocookie.com",
} as const;

export const getMatchedYoutubeUrls = (urls: string[]) => {
  const youtubeUrls = urls.filter(
    (url) =>
      url.includes(YOUTUBE_ORIGINS.DEFAULT) ||
      url.includes(YOUTUBE_ORIGINS.SHORT) ||
      url.includes(YOUTUBE_ORIGINS.NO_COOKIE),
  );
  if (youtubeUrls.length === 0) {
    return EMPTY_YOUTUBE_URL_MATCH_RESULT;
  }

  const parsed = youtubeUrls
    .map(parseYoutubeIdFromUrl)
    .filter((result) => result.videoId);

  return { hasYoutubeUrls: parsed.length > 0, matched: parsed };
};

const parseYoutubeIdFromUrl = (youtubeUrl: string): YoutubeUrlMatched => {
  const url = new URL(
    youtubeUrl.startsWith("http") ? youtubeUrl : `https://${youtubeUrl}`,
  );
  const paths = url.pathname.split("/").slice(1);
  const value = {
    hostname: url.hostname,
    firstPath: paths[0],
    secondPaths: paths[1] ?? "",
    searchV: url.searchParams.get("v") ?? "",
  };

  return (
    match(value)
      /** @example
       * {@link https://www.youtube.com/watch?v=CJAXnFgbHww}
       * {@link https://youtu.be/live?v=lPkrwy5ywS4}
       */
      .with(
        {
          hostname: P.string
            .includes(YOUTUBE_ORIGINS.SHORT)
            .or(P.string.includes(YOUTUBE_ORIGINS.DEFAULT)),
          searchV: P.string.minLength(1),
        },
        ({ searchV }) => {
          return {
            youtubeUrl,
            videoId: searchV,
          };
        },
      )
      /** @example {@link https://youtu.be/CJAXnFgbHww} */
      .with(
        {
          hostname: P.string.includes(YOUTUBE_ORIGINS.SHORT),
          firstPath: P.string.minLength(1),
          secondPaths: "",
        },
        () => {
          return {
            youtubeUrl,
            videoId: value.firstPath,
          };
        },
      )
      /** @example
       * {@link https://youtube.com/shorts/q3i4jtp-byQ}
       * {@link https://www.youtube.com/embed/CJAXnFgbHww}
       * {@link https://youtu.be/embed/CJAXnFgbHww}
       * {@link https://www.youtube-nocookie.com/embed/CJAXnFgbHww}
       * {@link https://www.youtube.com/live/XMD0IHC3-24}
       */
      .with(
        {
          firstPath: P.string.minLength(1),
          secondPaths: P.string.minLength(1),
        },
        ({ secondPaths }) => {
          return { youtubeUrl, videoId: secondPaths };
        },
      )
      .otherwise(() => {
        return { youtubeUrl, videoId: "" };
      })
  );
};
