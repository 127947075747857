import { type InferOutput, v } from "~libs/valibot";

export type SendYoutubeUrlRequest = InferOutput<
  typeof SendYoutubeUrlRequestSchema
>;

const SendYoutubeUrlRequestSchema = v.object({
  channelId: v.string(),
  url: v.string(),
});

const SendYoutubeUrlRequestSchema$Api = v.pipe(
  SendYoutubeUrlRequestSchema,
  v.transform((input) => ({
    url: input.url,
    channel_id: input.channelId,
  })),
);

export const transformSendYoutubeUrlRequest = (
  input: SendYoutubeUrlRequest,
) => {
  const parseResult = v.safeParse(SendYoutubeUrlRequestSchema$Api, input, {
    abortEarly: true,
  });
  if (!parseResult.success) {
    throw new Error(
      `[transformSendYoutubeUrlRequest] Invalid arguments\n${parseResult.issues.toString()}`,
    );
  }

  return parseResult.output;
};
