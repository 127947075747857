import { type PropsWithChildren, forwardRef } from "react";
import { cn } from "~utils/class-names";
import styles from "./result.module.scss";

type ResultSectionProps = PropsWithChildren<{
  className?: string;
}>;

// TODO: message 공통 UI로
export const ResultSection = forwardRef<HTMLDivElement, ResultSectionProps>(
  function ResultSection({ children, className }, ref) {
    return (
      <section ref={ref} className={cn(styles.result_section, className)}>
        {children}
      </section>
    );
  },
);
