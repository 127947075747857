import { useWindowWidth } from "~hooks/use-window-width";
import styles from "./main-page-prompt.module.scss";
import { MobileLayer } from "./mobile-layer";
import { PromptWithCompletion } from "./prompt-with-completion";
import { WithRawTextContext } from "./raw-text.context";
import { useMobileLayer } from "./use-mobile-layer";

export function MainPagePrompt() {
  const { isMobileSmallSize } = useWindowWidth();

  return (
    <WithRawTextContext key={"main-route"}>
      <div className={styles.main_prompt_wrapper}>
        {isMobileSmallSize ? (
          <MainPagePromptMobile />
        ) : (
          <MainPagePromptDesktop />
        )}
      </div>
    </WithRawTextContext>
  );
}

function MainPagePromptDesktop() {
  return <PromptWithCompletion className={styles.main_prompt} height="58px" />;
}

/** @todo search-header-mobile과 통합 */
function MainPagePromptMobile() {
  const { isOpen, setIsOpen } = useMobileLayer();

  return (
    <>
      {isOpen ? (
        <MobileLayer>
          <PromptWithCompletion
            className="absolute top-0"
            focusOnMount
            afterSendPrompt={() => setIsOpen(false)}
          />
        </MobileLayer>
      ) : (
        <PromptWithCompletion
          className={styles.main_prompt}
          onFocusTextarea={() => setIsOpen(true)}
        />
      )}
    </>
  );
}
