import type { MouseEvent, PropsWithChildren } from "react";

type BackgroundProps = PropsWithChildren<{
  closeDrawer: (e: MouseEvent) => void;
}>;

export function Background({ children, closeDrawer }: BackgroundProps) {
  return (
    <section
      className="bg-black opacity-10 w-full h-full"
      onClick={closeDrawer}
    >
      {children}
    </section>
  );
}
