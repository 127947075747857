import { createEventHandler } from "~utils/create-event-handler";
import { useModalVisibleLocal } from "./use-modal-visible-local";

import { UpdateNoticeImage } from "./images";
import styles from "./modal.module.scss";

type UpdateModalProps = {
  onClickConfirm: () => void;
  title: string;
  details: string;
  img?: string;
  imgWidthSize?: string;
  isTodayCloseBtn?: boolean;
};

export function UpdateModal({
  onClickConfirm,
  title,
  details,
  img,
  imgWidthSize,
  isTodayCloseBtn,
}: UpdateModalProps) {
  const { updateModalVisible } = useModalVisibleLocal();
  return (
    <div className={`${styles.modal_wrap} ${styles.update}`}>
      <div className={styles.modal}>
        <div className={styles.modal_contents}>
          {img ? (
            <img src={img} alt="" style={{ width: imgWidthSize }} />
          ) : (
            <img src={UpdateNoticeImage} alt="" />
          )}

          <h3>{title}</h3>
          <p>{details}</p>
        </div>
        <div className={styles.modal_btn_wrap}>
          <button
            type="button"
            className={styles.black}
            onClick={createEventHandler({ handler: onClickConfirm })}
          >
            <span>확인</span>
          </button>
        </div>
        {isTodayCloseBtn && (
          <button
            type="button"
            className={styles.btn_today_close}
            onClick={createEventHandler({
              handler: () => updateModalVisible(false, true),
            })}
          >
            <span>다시 보지 않기</span>
          </button>
        )}
      </div>
    </div>
  );
}
