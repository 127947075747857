import { useMutation, useQueryClient } from "@tanstack/react-query";
import { KyClient } from "~clients/fetch";
import { useChannel } from "~features/channel";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import type { SearchResult } from "~features/search/schemas";
import { Status } from "~features/util-types/status";
import { cloneObject } from "~utils/clone-object";
import { MESSAGE_ERROR_CONTENTS } from "../constants";

/** @todo channelId props 추가 */
export const useMutationCancelMessage = () => {
  const queryClient = useQueryClient();
  const { channelId } = useChannel();

  return useMutation({
    mutationFn: cancelRequest,

    onMutate: (requestId: string) => {
      queryClient.setQueryData<SearchResult.Page>(
        QUERY_KEYS.CHANNELS.getChannelMessagesQueryKey(channelId),
        (prevMessages) => {
          if (!prevMessages) {
            return;
          }

          const nextMessages = cloneObject(prevMessages);
          const targetMessage = nextMessages.items.find(
            (result) =>
              result.requestId === requestId ||
              result.status === Status.LOADING ||
              result.status === Status.INIT,
          );

          if (targetMessage) {
            targetMessage.status = Status.ERROR;
            targetMessage.content = MESSAGE_ERROR_CONTENTS.canceled;
            targetMessage.questions = undefined;
          }

          return nextMessages;
        },
      );
    },
  });
};

/**
 * POST `/api/v2/messages/cancel/${messageId}`
 * @example
 * @see {@link https://api.alan.est.ai/api/v2/messages/cancel/}
 *
 * @todo response 스키마
 */
const API_PATH = "api/v2/messages/cancel";

const cancelRequest = async (requestId: string) =>
  KyClient.post(`${API_PATH}/${requestId}`, {
    prefixUrl: import.meta.env.VITE_GEPETO_API_BASE_URL
  });
