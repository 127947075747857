import { type PropsWithChildren, useEffect, useRef, useState } from "react";
import AccordionButton from "~features/ui/accordion-button/accordion-button";
import { cn } from "~utils/class-names";
import styles from "./message-title.module.scss";

const MAX_TITLE_HEIGHT = 4.5 * 28;

type MessageTitleProps = PropsWithChildren<{
  className?: string;
}>;

/**
 * @todo props => context api로 변경
 */
export function MessageTitle({ children, className }: MessageTitleProps) {
  const [isTitleOpen, setIsTitleOpen] = useState(false);
  const [isOverflowsTitle, setIsOverflowsTitle] = useState(false); // 타이틀이 넘치는지 여부
  const titleRef = useRef<HTMLDivElement | null>(null);

  const toggleTitleOpen = () => {
    setIsTitleOpen((prev) => !prev);
  };

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (titleRef.current) {
        const elementHeight = titleRef.current.scrollHeight;
        setIsOverflowsTitle(elementHeight > MAX_TITLE_HEIGHT);
      }
    });

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  return (
    <div
      className={cn(
        styles.wrapper,
        { [styles.overflows]: isOverflowsTitle },
        { [styles.open]: isTitleOpen },
        className,
      )}
      style={{
        maxHeight: isTitleOpen ? "none" : `${MAX_TITLE_HEIGHT}px`,
      }}
      onClick={isOverflowsTitle ? toggleTitleOpen : undefined}
    >
      <h2 className={styles.title}>
        {isOverflowsTitle && (
          <span className={styles.btn_open}>
            <AccordionButton isOpen={isTitleOpen} />
          </span>
        )}

        <div ref={titleRef} className={styles.title_text}>
          {children}
        </div>
      </h2>
    </div>
  );
}
