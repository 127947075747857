import { queryOptions, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import { useUser } from "~features/auth";
import { useChannel } from "~features/channel";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { SuggestionsLoadingStatus } from "./message-list";
import {
  SUMMARY_RESULT_INIT,
  SummaryStatus,
  transformResponse,
} from "./summary-result/youtube-summary.schema";

export const useQueryRequestSummary = () => {
  const { channelId } = useChannel();
  const { isLoggedIn } = useUser();
  const queryOption = useMemo(
    () =>
      Object.assign({}, getYoutubeSummaryQueryOptions(channelId), {
        enabled: isLoggedIn && channelId.length > 0,
      }),
    [channelId, isLoggedIn],
  );

  return useQuery(queryOption);
};

export const getYoutubeSummaryQueryOptions = (channelId: string) =>
  queryOptions({
    enabled: channelId.length > 0,
    queryKey: QUERY_KEYS.SUMMARY.getYoutubeResultQueryKey(channelId),
    queryFn: () => fetchYoutubeDetail(channelId).then(transformResponse),

    refetchOnWindowFocus: false,
    refetchOnMount(query) {
      const { data } = query.state;
      return data?.status === SummaryStatus.success ? false : "always";
    },
    refetchInterval(query) {
      const { data } = query.state;
      if (data?.status !== SummaryStatus.success) return 2 * TIMES.SEC;
      return data?.suggestionsLoading === SuggestionsLoadingStatus.SUCCESS
        ? false
        : 1 * TIMES.SEC;
    },
    refetchIntervalInBackground: true,
    staleTime: TIMES.SEC * 1,

    retry: 1,
    retryDelay: TIMES.SEC * 1,

    placeholderData: SUMMARY_RESULT_INIT,
  });

/**
 * examples:
 * - @see {@link https://func08-api-estsoft-algpt-stage.azurewebsites.net/api/v1/youtube/summary?channel_id=66961d97a2067b43a00554d9}
 */
const API_PATH = "youtube/summary";

const fetchYoutubeDetail = async (channelId: string) => {
  return await KyClient.get(API_PATH, {
    searchParams: {
      channel_id: channelId,
    },
  }).json();
};
