import type {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  RefObject,
} from "react";
import { createContext, useContext } from "react";

export type PromptContextStates = {
  sendPrompt: (prompt?: string) => Promise<void>;

  cancelPrompt?: () => Promise<void>;

  placeholderText: string;

  textareaRef: RefObject<HTMLTextAreaElement>;
  textareaEnabled: boolean;
  textareaRows?: number;

  onChangeTextarea?: ChangeEventHandler<HTMLTextAreaElement>;
  onFocusTextarea?: FocusEventHandler<HTMLTextAreaElement>;
  onBlurTextarea?: FocusEventHandler<HTMLTextAreaElement>;
  onClickTextarea?: MouseEventHandler<HTMLTextAreaElement>;
  onKeyUpTextarea?: KeyboardEventHandler<HTMLTextAreaElement>;

  isMobileSize?: boolean;
  isSttEnabled?: boolean;
  onClickSttButton?: MouseEventHandler<HTMLButtonElement>;
};

export const PromptContext = createContext<PromptContextStates | undefined>(
  undefined,
);

export const usePromptContext = () => {
  const context = useContext(PromptContext);
  if (!context) {
    throw new Error("usePromptContext must be used within a PromptProvider");
  }

  return context;
};
