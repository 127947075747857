import { create } from "zustand";

type ModalVisibleStates = {
  isModalVisible: boolean;
};

type ModalVisibleActions = {
  updateModalVisible: (visibility: boolean, saveLocalStorage?: boolean) => void;
};

const STORAGE_KEY = "alan.modal.update.visibility.2025012400";

/** @todo BrowserStorage 서비스 분리 */
const getStorageItem = (storageKey: string) => {
  try {
    const item = window.localStorage.getItem(storageKey);
    return item ? JSON.parse(item) : undefined;
  } catch {
    return undefined;
  }
};

// TODO: 추후 config api 연동
const getInitState = () => {
  // if (new Date().getTime() >= new Date("2024-06-20T05:00").getTime()) {
  //   return false;
  // }
  // return false;

  return getStorageItem(STORAGE_KEY) === undefined
    ? true
    : getStorageItem(STORAGE_KEY);
};

const store = create<ModalVisibleStates & ModalVisibleActions>((set) => ({
  isModalVisible: getInitState(),
  updateModalVisible: (isModalVisible, saveLocalStorage) => {
    if (saveLocalStorage) {
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(isModalVisible));
    }
    set({ isModalVisible });
  },
}));

export const useModalVisibleLocal = () => {
  const { isModalVisible, updateModalVisible } = store();

  return {
    isModalVisible,
    updateModalVisible,
  };
};
