import {
  type CSSProperties,
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";
import { useQueryCompletions } from "~features/completions";
import type { Completions } from "~features/completions/types";
import { useListScrollEventContext } from "~features/messages/scroll";
import { useFirstPromptContext } from "~features/prompt/first-prompt";
import { useWindowWidth } from "~hooks/use-window-width";

type RelatedSearchQueriesContextStates = {
  enabled: boolean;
  relatedQueries: Completions.List;
  headerHeight: string;
  relatedQueriesStyle: CSSProperties;
};

const RelatedSearchQueriesContext =
  createContext<RelatedSearchQueriesContextStates | null>(null);

export const useRelatedSearchQueries = () => {
  const context = useContext(RelatedSearchQueriesContext);
  if (!context) {
    throw new Error(
      "useRelatedSearchQueries must be used within a RelatedSearchQueriesContextProvider",
    );
  }
  return context;
};

type WithRelatedSearchQueriesProps = PropsWithChildren<{ enabled?: boolean }>;

export function WithRelatedSearchQueries({
  children,
  enabled: enabledProp = true,
}: WithRelatedSearchQueriesProps) {
  const { firstPrompt } = useFirstPromptContext();
  const { data: completions = [], isPending } = useQueryCompletions({
    keyword: enabledProp ? firstPrompt : "",
  });

  const enabled = useMemo(() => {
    return enabledProp && !isPending && completions.length > 0;
  }, [enabledProp, isPending, completions.length]);

  const { isMobileSmallSize } = useWindowWidth();
  const { distance } = useListScrollEventContext();
  const headerHeight = useMemo(() => {
    if (!enabled) return "auto";
    return `${distance + (isMobileSmallSize ? 146 : 98)}px`;
  }, [enabled, isMobileSmallSize, distance]);

  const relatedQueriesStyle = useMemo(
    () =>
      ({
        transform: `translateY(${distance}px)`,
        visibility: distance === -30 ? "hidden" : "visible",
      }) as CSSProperties,
    [distance],
  );

  return (
    <RelatedSearchQueriesContext.Provider
      value={{
        enabled,
        relatedQueries: completions.slice(0, 5),
        headerHeight,
        relatedQueriesStyle,
      }}
    >
      {children}
    </RelatedSearchQueriesContext.Provider>
  );
}
