import { getMatchedUrls, getMatchedYoutubeUrls } from "./matched-urls";

type CategorizedPrompt = {
  origin: string;
  hasUrl: boolean;
  urls?: string[];
  hasYoutubeUrl: boolean;
  youtubeId?: string;
};

const EMPTY_CATEGORIZED_PROMPT: CategorizedPrompt = {
  origin: "",
  hasUrl: false,
  hasYoutubeUrl: false,
} as const;

export const categorizePrompt = (prompt: string): CategorizedPrompt => {
  const categorizedPrompt: CategorizedPrompt = Object.assign(
    { origin: prompt },
    EMPTY_CATEGORIZED_PROMPT,
  );

  const matchedUrls = getMatchedUrls(prompt);
  if (!matchedUrls.hasUrls) {
    return categorizedPrompt;
  }

  categorizedPrompt.hasUrl = true;
  categorizedPrompt.urls = matchedUrls.matches;

  const matchedYoutubeUrls = getMatchedYoutubeUrls(matchedUrls.matches);
  if (!matchedYoutubeUrls.hasYoutubeUrls) {
    return categorizedPrompt;
  }

  categorizedPrompt.hasYoutubeUrl = true;
  categorizedPrompt.youtubeId = matchedYoutubeUrls.matched[0].videoId;
  categorizedPrompt.urls = [matchedYoutubeUrls.matched[0].youtubeUrl];

  return categorizedPrompt;
};
