import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef } from "react";
import useWebSocket from "react-use-websocket";
import { P } from "ts-pattern";
import { useNativeBridge } from "~clients/native-bridge";
import { createSocketMessageHandler } from "~clients/socket";
import { WEBSOCKET_PROTOCOL } from "~clients/socket/constants";
import { useQuerySocketUrl } from "~clients/socket/use-query-socket-url";
import { MESSAGE_STATUS } from "~features/messages/constants";
import { useTimeoutError } from "~features/messages/hooks";
import { SocketMessageParsers } from "~features/messages/schemas";
import { useStreamingContext } from "~features/messages/streaming";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { Status } from "~features/util-types/status";
import { logger } from "~utils/logger";
import { RESULT_MESSAGE_PATTERNS } from "../schemas";

const INIT_ARRAY_LENGTH = 1_500;

/** @todo Refactor - 유틸 함수로 분리 */
const REFERENCES_REGEXP_IN_ANSWER =
  /(\[\^[0-9]{1,9}\^\]|\[(?:\^(?:[0-9]{1,9}(?:\^\]?)?)?)?$)/g;
const FOOTNOTE_REGEXP_IN_ANSWER =
  /(\[\^[0-9]{1,9}\^\](?:\:.*\n?)?|\[(?:\^(?:[0-9]{1,9}(?:\^\]?)?)?)?$)/g;

/** @todo 코어로직 useSocketV2로 분리 */
export const useSocketSearchStreaming = (channelId: string) => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => QUERY_KEYS.CHANNELS.getChannelMessagesQueryKey(channelId),
    [channelId],
  );

  const { data: channelSocketUrl, isSuccess } = useQuerySocketUrl();
  const { lastJsonMessage: lastData, sendJsonMessage } = useWebSocket(
    channelSocketUrl ?? "",
    {
      share: true,
      protocols: WEBSOCKET_PROTOCOL,

      /** @todo message 직접 보내면 연결 끊어짐? */
      heartbeat: false,

      onError: (_event) => {
        logger.error({
          scope: "useSocketSearch.onError",
          data: {
            channelId,
            channelSocketUrl,
          },
        });
      },
    },
    isSuccess && channelSocketUrl.length > 0,
  );

  const { setStatus, setContent, setToolSteps } = useStreamingContext();
  const tokensQueue = useRef<string[]>([]);
  const prevMessageContentRef = useRef("");

  const { nativeBridge } = useNativeBridge();

  const handleSocketMessage = useCallback(
    createSocketMessageHandler({ channelId, sendJsonMessage }),
    [],
  );

  const { debouncedTimeoutHandler } = useTimeoutError();

  /** @todo 공통 로직 분리 */
  const createNotificationMessage = useCallback(
    (messageId: string) => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("message_id", messageId);
      searchParams.delete("platform");

      return {
        title: "검색이 완료됐어요!",
        content: "지금 검색 결과를 확인하세요.",
        uri: `/search/${channelId}?${searchParams.toString()}`,
      };
    },
    [channelId],
  );

  const resetStates = useCallback(() => {
    queryClient.invalidateQueries({ queryKey });
    queryClient.invalidateQueries({ queryKey: QUERY_KEYS.AUTH.USER });
    prevMessageContentRef.current = "";
    tokensQueue.current = [];
  }, [queryClient, queryKey]);

  useEffect(() => {
    handleSocketMessage(lastData)
      .with(RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.IN_ANSWER], ({ data }) => {
        const { order, token } =
          SocketMessageParsers[MESSAGE_STATUS.IN_ANSWER](data);
        tokensQueue.current[order] = token;

        setStatus(Status.LOADING);
        setContent(
          prevMessageContentRef.current
            .concat(tokensQueue.current.join(""))
            .replace(FOOTNOTE_REGEXP_IN_ANSWER, "")
            .replace(REFERENCES_REGEXP_IN_ANSWER, ""),
        );
        debouncedTimeoutHandler();
      })
      .with(RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.SAVED], ({ data }) => {
        const { requestId } = SocketMessageParsers[MESSAGE_STATUS.SAVED](data);
        tokensQueue.current = new Array(INIT_ARRAY_LENGTH).fill("");

        debouncedTimeoutHandler();
      })
      .with(RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.START_AGENT], ({ data }) => {
        const { requestId } =
          SocketMessageParsers[MESSAGE_STATUS.START_AGENT](data);

        setStatus(Status.LOADING);
        debouncedTimeoutHandler();
      })
      .with(
        RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.CONTINUE_START_AGENT],
        ({ data }) => {
          const { requestId } =
            SocketMessageParsers[MESSAGE_STATUS.CONTINUE_START_AGENT](data);

          setStatus(Status.LOADING);
          debouncedTimeoutHandler();
        },
      )
      .with(RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.TOOL_ACTION], ({ data }) => {
        const { toolSteps } =
          SocketMessageParsers[MESSAGE_STATUS.TOOL_ACTION](data);

        setStatus(Status.LOADING);
        setToolSteps((prevSteps) =>
          (prevSteps.length < toolSteps.length ? toolSteps : prevSteps).sort(
            (a, b) => a.order - b.order,
          ),
        );
        debouncedTimeoutHandler();
      })
      .with(
        RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.FINISH_ANSWER],
        ({ data }) => {
          const { requestId, content, images, videos, sources, id, toolSteps } =
            SocketMessageParsers[MESSAGE_STATUS.FINISH_ANSWER](data);

          resetStates();
          nativeBridge?.requestNotification(createNotificationMessage(id));
          debouncedTimeoutHandler.cancel();
        },
      )
      .with(
        RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.PAUSED_BY_LENGTH],
        ({ data }) => {
          const { requestId, content, images, videos, sources, id, toolSteps } =
            SocketMessageParsers[MESSAGE_STATUS.PAUSED_BY_LENGTH](data);

          resetStates();
          nativeBridge?.requestNotification(createNotificationMessage(id));
          debouncedTimeoutHandler.cancel();
        },
      )
      .with(
        RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.RELATED_QUESTIONS],
        ({ data }) => {
          const { messageId, questions } =
            SocketMessageParsers[MESSAGE_STATUS.RELATED_QUESTIONS](data);

          debouncedTimeoutHandler.cancel();
        },
      )
      .with(RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.CANCEL], ({ data }) => {
        const { requestId } = SocketMessageParsers[MESSAGE_STATUS.CANCEL](data);

        debouncedTimeoutHandler.cancel();
        resetStates();
      })
      .with(
        RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.ERROR_CONTENT_FILTERED],
        ({ data }) => {
          const { requestId } =
            SocketMessageParsers[MESSAGE_STATUS.ERROR_CONTENT_FILTERED](data);

          debouncedTimeoutHandler.cancel();
          resetStates();
        },
      )
      .with(RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.ERROR], ({ data }) => {
        const { requestId } = SocketMessageParsers[MESSAGE_STATUS.ERROR](data);

        debouncedTimeoutHandler.cancel();
        resetStates();
      })
      /** @todo 없겠지만 확인필요 */
      .with(
        {
          data: {
            user: {
              user_role: P.not("assistant"),
            },
          },
        },
        ({ data }) => {
          logger.debug({
            scope: "useSocketSearch.handleSocketMessage",
            message: "not assistant message",
            data,
          });
        },
      )
      .otherwise(() => {
        logger.debug({
          scope: "useSocketSearch.handleSocketMessage",
          message: "not matched message",
          data: lastData as Record<string, unknown>,
        });
      });
  }, [
    lastData,
    handleSocketMessage,
    setStatus,
    setContent,
    setToolSteps,
    resetStates,
    createNotificationMessage,
    nativeBridge,
    debouncedTimeoutHandler,
  ]);
};
