import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { KyClient, isKyHttpError } from "~clients/fetch";
import { PermissionNames, useNativeBridge } from "~clients/native-bridge";
import { useUser } from "~features/auth";
import { usePersonas } from "~features/personas";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import type { SearchResult } from "~features/search/schemas";
import { useUserAgent } from "~features/user-agent";
import { Status } from "~features/util-types/status";
import {
  type SendMessageRequests,
  transformSendMessageRequests,
} from "~features/youtube-summary/send-message-request.types";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "~utils/localstorage-value";
import { logger } from "~utils/logger";

const STORAGE_KEY = "gepeto.alreadyPermissionToPush";

export const useSendSearchTitle = () => {
  const { isIos } = useUserAgent();
  const { nativeBridge } = useNativeBridge();
  const { user } = useUser();
  const { persona } = usePersonas();

  const sendSearchTitleMutation = useMutationSendSearchTitle({
    userId: user.id,
    personaId: persona?.id ?? "",
  });

  // TODO: 이것만 따로 분리?
  const requestNativeNotificationPermission = useCallback(() => {
    if (!nativeBridge) return;
    if (isIos) return;
    if (nativeBridge.hasPermission(PermissionNames.pushNotification)) return;

    const hasRequestedPermission = getLocalStorageValue<boolean | undefined>(
      STORAGE_KEY,
    );
    if (typeof hasRequestedPermission !== "undefined") return;

    nativeBridge
      .requestPermissionAsync({
        permission: PermissionNames.pushNotification,
        message: "답장이 왔을때 알려드릴까요?",
      })
      .then(({ granted }) => {
        setLocalStorageValue(STORAGE_KEY, granted);
      });
  }, [nativeBridge, isIos]);

  return {
    sendSearchTitleMutation,
    requestNativeNotificationPermission,
  };
};

type SendSearchTitleMutationArgs = Pick<
  SendMessageRequests,
  "userId" | "personaId"
>;

const useMutationSendSearchTitle = (args: SendSearchTitleMutationArgs) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: sendSearchTitle(args),

    onMutate: async ({ channelId, message }) => {
      const queryKey =
        QUERY_KEYS.CHANNELS.getChannelMessagesQueryKey(channelId);
      const prevMessages = queryClient.getQueryData(queryKey) ?? [];
      await queryClient.cancelQueries({ queryKey });

      queryClient.setQueryData<SearchResult.Page>(
        queryKey,
        (prevSearchResults) => {
          const newSearch: SearchResult.Item = createSearchItem(message);

          if (!prevSearchResults)
            return {
              items: [newSearch],
              total: 1,
            };

          return {
            items: prevSearchResults.items.concat(newSearch),
            total: prevSearchResults.total + 1,
          };
        },
      );
      return { queryKey, prevMessages };
    },

    onError: async (error, _args, context) => {
      if (context) {
        queryClient.setQueryData(context.queryKey, context.prevMessages ?? []);
      }

      // TODO: handlers
      if (isKyHttpError(error)) {
        const statusCode = error.response.status;
        const response = await error.response.json();

        logger.error({
          scope: "useMutationSendSearchTitle.KyError",
          data: {
            statusCode,
            response,
          },
        });
      } else {
        logger.error({
          scope: `useMutationSendSearchTitle.UnknownError.${error.name}`,
          message: error.message,
          data: { stack: error.stack },
        });
      }
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.HISTORY.getHistoryQueryKey({}),
      });
    },
  });
};

type SendSearchTitleArgs = Pick<SendMessageRequests, "channelId" | "message">;

const sendSearchTitle =
  ({ userId, personaId }: SendSearchTitleMutationArgs) =>
  async ({ message, channelId }: SendSearchTitleArgs) => {
    const validationResults = transformSendMessageRequests({
      userId,
      personaId,
      channelId,
      message,
    });
    if (!validationResults.success) {
      throw new Error("[useSendSearchTitle] Invalid arguments");
    }

    return await KyClient.post(`channels/${channelId}/messages`, {
      json: validationResults.output,
    });
  };

const INIT_SEARCH_ITEM: Omit<SearchResult.Item, "title"> = {
  id: "",
  requestId: "",
  messageId: { user: "", assistant: "" },
  status: Status.INIT,
  toolSteps: [
    {
      order: 0,
      speak: "질문의 의도를 이해하고 있어요.",
      status: Status.LOADING,
    },
  ],
} as const;

const createSearchItem = (SearchTitle: string): SearchResult.Item =>
  Object.assign({}, INIT_SEARCH_ITEM, { title: SearchTitle });
