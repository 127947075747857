import { useMutation } from "@tanstack/react-query";
import { match } from "ts-pattern";
import {
  KyClient,
  STATUS_CODES,
  isKyHttpError,
  parseKyHttpError,
} from "~clients/fetch";
import { usePersonas } from "~features/personas";
import { setDefaultErrorToast } from "~features/toast";
import { type ChannelId, transformResponse } from "./create-channel.schema";

type CreateChannelIdArgs = { personaId: string };

export const useMutationCreateChannelId = () => {
  const { persona } = usePersonas();

  return useMutation({
    mutationFn: async (
      // biome-ignore lint/suspicious/noConfusingVoidType: 호출부에서 인자에 아무것도 안넘겨도 되도록
      personaId: string | void = persona?.id ?? "",
    ) => {
      return match({ personaId })
        .returnType<Promise<ChannelId>>()
        .with({ personaId: "" }, async () => {
          throw new Error("[useMutationCreateChannelId] Persona Not Found");
        })
        .otherwise(async ({ personaId }) => {
          return createChannelId({ personaId: personaId as string }).then(
            transformResponse,
          );
        });
    },

    onError: async (error) => {
      if (isKyHttpError(error)) {
        const { status, response } = (await parseKyHttpError<unknown>(error))
          .data;
        match(status).with({ code: STATUS_CODES.RATE_LIMIT }, () => {
          setDefaultErrorToast(response as string);
        });
      }
    },
  });
};

/**
 * Example
 * - {@link https://func08-api-estsoft-algpt-stage.azurewebsites.net/api/v1/channels}
 */
const API_PATH = "channels";

const createChannelId = async ({ personaId }: CreateChannelIdArgs) => {
  return KyClient.post(API_PATH, {
    json: {
      persona_id: personaId,
    },
  }).json();
};
